import { compose } from '@reduxjs/toolkit';
import { Col, Flex, Row, Skeleton, Typography } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ModalTitle, NJVButton, NJVCard } from '../../components/core-component';
import { SvgDepartmentOutlined, SvgGenderOutlined, SvgHightLightFilled, SvgJobLocation, SvgOpportunity, SvgSalaryOutlined, SvgThumbUp } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandlerNoAuth } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';

class JobPostDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            data: null
        }
    }

    componentDidMount() {
        this.fetchData()

        if (this.props.location.state?.scrollTop) {
            const element = document.getElementById('titleElement')
            element.scrollIntoView()
        }
    }

    fetchData = () => {

        this.setState({
            isLoading: true
        })
        ApiHandlerNoAuth({ url: Api.job_post_detail, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            .then((response) => {
                let description = response.jobDescription;
                let requirements = response.jobRequirements;
                const replaceHtmlContent = (htmlString) => {
                    return htmlString
                        .replace(/<ol>/g, '<ul>')
                        .replace(/<\/ol>/g, '</ul>')
                        .replace(/background-color:\s?rgb\(249,\s?249,\s?249\)/g, 'background-color: #F5F7FA');
                };
                description = replaceHtmlContent(description);
                requirements = replaceHtmlContent(requirements);

                this.setState({
                    data: { ...response, jobDescription: description, jobRequirements: requirements }
                })
            }).catch(() => {

            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const { data, isLoading } = this.state;

        return (
            <Row id='titleElement'>
                <Col span={24} style={{ background: Theme.colors.white }}>
                    <Row style={{ paddingBottom: 20 }}>
                        <Col lg={3} md={1} xs={1} sm={1} />
                        <Col lg={18} md={22} xs={22} sm={22}>
                            {
                                isLoading ? <Skeleton /> :
                                    <Flex justify='space-between' align='end' wrap="wrap" gap={20}>
                                        <Flex vertical gap={10}>
                                            <Typography.Title level={4} style={{ color: '#515357', marginTop: 0 }}>{data?.position}</Typography.Title>
                                            <Flex vertical gap={10}>
                                                <Flex gap={10}>
                                                    <SvgDepartmentOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span style={{ color: '#808080', marginBlock: 0, fontSize: 17, fontWeight: 600 }}>{data?.department}</span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgJobLocation width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>{data?.location}</span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgGenderOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>
                                                        {data?.gender === 'Male' ? i18next.t('male') : data?.gender === 'Female' ? i18next.t('female') : data?.gender === 'Open To All' ? i18next.t('open_to_all') : ''}
                                                    </span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgSalaryOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>{data?.salary === 'Negotiable' ? i18next.t('negotiable') : data?.salary}</span>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Link to={`${CustomPath.public_job_form}/${data?.id}`}>
                                            <NJVButton type="primary" buttonprimary={Theme.colors.primary}>{i18next.t('apply_job')}</NJVButton>
                                        </Link>
                                    </Flex>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ background: Theme.colors.ant_bg_color, paddingTop: 20, paddingBottom: 30 }}>
                    <Row>
                        <Col lg={3} md={1} xs={1} sm={1} />
                        <Col lg={18} md={22} xs={22} sm={22}>
                            {
                                isLoading ? <Skeleton /> :
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <ModalTitle label={i18next.t('job_descriptions')} />
                                                <div dangerouslySetInnerHTML={{ __html: data?.jobDescription }} />
                                            </Col>
                                            <Col span={24}>
                                                <ModalTitle label={i18next.t('job_requirements')} />
                                                <div dangerouslySetInnerHTML={{ __html: data?.jobRequirements }} />
                                            </Col>
                                            {
                                                data?.note &&
                                                <Col span={24}>
                                                    <ModalTitle label={i18next.t('note')} />
                                                    <Typography.Paragraph>{data.note}</Typography.Paragraph>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                            }
                            <Row gutter={[0, 16]} style={{ marginTop: 20 }}>
                                <Col span={24}>
                                    <NJVCard style={{ border: Theme.colors.primary }}>
                                        <ModalTitle label={"What We Can Offer"} style={{ marginBottom: 10 }} />
                                        <Row>
                                            <Col lg={8} md={8} sm={24} xs={24}>
                                                <Flex gap={20} style={{ marginBlock: 10 }}>
                                                    <SvgThumbUp width={20} height={20} color={Theme.colors.primary} />
                                                    <Flex vertical>
                                                        <span style={{ fontSize: 15, fontWeight: 500 }}>Benefits</span>
                                                        <Flex>
                                                            <Typography.Paragraph style={{ color: Theme.colors.gray, marginBottom: 0 }}>
                                                                <ul>
                                                                    <li>
                                                                        Attractive Salary + Other Allowances
                                                                    </li>
                                                                    <li>
                                                                        Ferry & Uniform Provided
                                                                    </li>
                                                                    <li>
                                                                        Phone SIM card and Phone Bill Provided
                                                                    </li>
                                                                    <li>
                                                                        Performance-based Annual Increment & Bonus
                                                                    </li>
                                                                </ul>
                                                            </Typography.Paragraph>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Col>
                                            <Col lg={8} md={8} sm={24} xs={24}>
                                                <Flex gap={20} style={{ marginBlock: 10 }}>
                                                    <SvgHightLightFilled width={20} height={20} color={Theme.colors.primary} />
                                                    <Flex vertical>
                                                        <span style={{ fontSize: 15, fontWeight: 500 }}>Hightlights</span>
                                                        <Flex>
                                                            <Typography.Paragraph style={{ color: Theme.colors.gray, marginBottom: 0 }}>
                                                                <ul>
                                                                    <li>
                                                                        Fun & Dynamic Environment
                                                                    </li>
                                                                    <li>
                                                                        Multinational Organization
                                                                    </li>
                                                                    <li>
                                                                        Secure and Reliable Work environment
                                                                    </li>
                                                                </ul>
                                                            </Typography.Paragraph>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Col>
                                            <Col lg={8} md={8} sm={24} xs={24}>
                                                <Flex gap={20} style={{ marginBlock: 10 }}>
                                                    <SvgOpportunity width={20} height={20} color={Theme.colors.primary} />
                                                    <Flex vertical>
                                                        <span style={{ fontSize: 15, fontWeight: 500 }}>Career Opportunities</span>
                                                        <Flex>
                                                            <Typography.Paragraph style={{ color: Theme.colors.gray, marginBottom: 0 }}>
                                                                <ul>
                                                                    <li>
                                                                        Continuous Learning Opportunities
                                                                    </li>
                                                                    <li>
                                                                        Opportunities for Career Development
                                                                    </li>
                                                                </ul>
                                                            </Typography.Paragraph>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Col>
                                        </Row>

                                    </NJVCard>
                                </Col>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Link to={`${CustomPath.public_job_form}/${data?.id}`} state={{ scrollTop: true }}>
                                        <NJVButton type="primary" buttonprimary={Theme.colors.primary}>{i18next.t('apply_job')}</NJVButton>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(JobPostDetail)