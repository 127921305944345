import { CalendarOutlined, CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Button, Checkbox, Col, DatePicker, Flex, Form, Image, List, message, Modal, Pagination, Row, Skeleton, Space, Typography } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import CancelledImg from '../../asset/cancelled.png';
import DeliveredImg from "../../asset/delivered.png";
import DeliveryExceptionImg from '../../asset/delivery exception.png';
import CardBg from '../../asset/ninja-parcel-history.png';
import OnvehicleImg from '../../asset/on vehicle.png';
import PendingPickupImg from '../../asset/pending pick.png';
import PickupImg from '../../asset/picked up.png';
import ProcessingImg from '../../asset/processing.png';
import rtsImg from '../../asset/rts.png';
import { MainTitle, NJVButton, NJVCard, NJVCheckbox, NJVForm, NJVInput, NJVSelect } from "../../components/core-component";
import { SvgCopyable, SvgParcelCancel, SvgSearchOutlined, SvgUser, SvgUserPhone } from "../../components/custom-svgIcon";
import { NJVCustomOrderStatusTag, NJVDeliveryStep } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { FetchOrderHistory } from "../../network/api-service";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, downloadExcel } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { RangePicker } = DatePicker;

const monthMap = {
    '0': 'January',
    '1': 'February',
    '2': 'March',
    '3': 'April',
    '4': 'May',
    '5': 'June',
    '6': 'July',
    '7': 'August',
    '8': 'September',
    '9': 'October',
    '10': 'November',
    '11': 'December',
}

const PENDING_PICK_UP = 'Pending Pickup'
const ARRIVED_AT_SORTING_HUB = 'Arrived at Sorting Hub'
const EN_ROUTE_TO_SORTING_HUB = 'En-route to Sorting Hub'
const ON_VEHICLE_FOR_DELIVERY = 'On Vehicle for Delivery'
const COMPLETED = 'Delivered'
const PENDING_RESCHEDULE = 'Pending Reschedule'
const RETURNED_TO_SENDER = 'Returned to Sender'
const CANCELLED = 'Cancelled'


const eventMap = {
    'PENDING_PICK_UP': 'Pending Pickup',
    'ARRIVED_AT_SORTING_HUB': 'Arrived at Sorting Hub',
    'EN_ROUTE_TO_SORTING_HUB': 'En-route to Sorting Hub',
    'ON_VEHICLE_FOR_DELIVERY': 'On Vehicle for Delivery',
    'COMPLETED': 'Delivered',
    'PENDING_RESCHEDULE': 'Pending Reschedule',
    'RETURNED_TO_SENDER': 'Returned to Sender',
    'CANCELLED': 'Cancelled'
}

const orderStatusList = [
    {
        key: 1,
        label: PENDING_PICK_UP,
        value: 'PENDING_PICK_UP'
    }, {
        key: 2,
        label: ARRIVED_AT_SORTING_HUB,
        value: 'ARRIVED_AT_SORTING_HUB'
    }, {
        key: 3,
        label: EN_ROUTE_TO_SORTING_HUB,
        value: 'EN_ROUTE_TO_SORTING_HUB'
    }, {
        key: 4,
        label: ON_VEHICLE_FOR_DELIVERY,
        value: 'ON_VEHICLE_FOR_DELIVERY'
    }, {
        key: 5,
        label: COMPLETED,
        value: 'COMPLETED'
    }, {
        key: 6,
        label: PENDING_RESCHEDULE,
        value: 'PENDING_RESCHEDULE'
    }, {
        key: 7,
        label: RETURNED_TO_SENDER,
        value: 'RETURNED_TO_SENDER'
    }, {
        key: 7,
        label: CANCELLED,
        value: 'CANCELLED'
    },
]
const empty_history_image = require('../../asset/shipper_history_empty.png')

const imageMap = {
    'PENDING_PICK_UP': PendingPickupImg,
    'ARRIVED_AT_SORTING_HUB': ProcessingImg,
    'ARRIVED_AT_TRANSIT_HUB': ProcessingImg,
    'ARRIVED_AT_ORIGIN_HUB': ProcessingImg,
    'ARRIVED_AT_DESTINATION_HUB': ProcessingImg,
    'IN_TRANSIT_TO_NEXT_SORTING_HUB': ProcessingImg,
    'AT_PUDO': ProcessingImg,
    'DELIVERY_EXCEPTION': DeliveryExceptionImg,
    'ON_VEHICLE_FOR_DELIVERY': OnvehicleImg,
    'DELIVERED': DeliveredImg,
    'PICKED_UP': PickupImg,
    'RETURNED_TO_SENDER': rtsImg,
    'CANCELLED': CancelledImg,
    'PICK_UP_EXCEPTION': ProcessingImg
}

class ParcelHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_history_collection: [],
            historyMap: {},
            titleList: [],
            isDataFetching: true,
            is_fetching_order_status: true,
            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            filterData: {},
            orders_status_list: [],
            selected_order: [],
            isCheckAll: false,
            isCheckSpecific: false,
            selectedTrackingNumbers: [],
            isParcelCanceling: false
        }
        this.formRef = React.createRef()
    }

    componentDidMount() {
        this.fetchOrderStatus()
        let urlParam = new URLSearchParams(window.location.search);
        let urlPage = urlParam.get('page');
        if (urlPage) {
            const currentUrl = window.location.href;
            const index = currentUrl.indexOf('?');
            const searchParams = new URLSearchParams(currentUrl.substring(index));
            let filterData = {};

            for (let [key, value] of searchParams.entries()) {
                if (key !== 'page' && value !== null && value !== undefined && value !== 'null' && value !== 'undefined') {
                    filterData[key] = value;
                }
            }
            if (filterData?.fromDate && filterData?.toDate) {
                filterData = { ...filterData, fromDate: dayjs(filterData?.fromDate), toDate: dayjs(filterData?.toDate) }
            }
            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
            }

            if (filterData.purchasedInsurance) {
                filterData = { ...filterData, 'purchasedInsurance': true }
            } else {
                filterData = { ...filterData, 'purchasedInsurance': false }
            }

            this.setState({
                filterData
            }, () => {
                this.fetchData(urlPage)
                if (filterData) {
                    this.formRef.current.setFieldsValue({ ...filterData })
                }
            })
        } else {
            urlPage = 1;
            urlParam.set('page', urlPage);
            const currentUrl = new URL(window.location.href);
            const newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
            window.history.replaceState({}, '', newUrl);
            this.fetchData(urlPage);
        }
    }

    fetchOrderStatus = async () => {
        try {
            var requestParams = {
                'enumType': 'ORDER_STATUS'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let temp = []
                response.map((res) => {
                    if (res.key === 'ARRIVED_AT_ORIGIN_HUB' || res.key === 'ARRIVED_AT_SORTING_HUB' || res.key === 'ARRIVED_AT_TRANSIT_HUB' ||
                        res.key === 'ARRIVED_AT_DESTINATION_HUB' || res.key === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || res.key === 'AT_PUDO') {
                    } else {
                        temp.push({
                            value: res.key,
                            label: res.value,
                        });
                    }
                })
                this.setState({
                    orders_status_list: temp
                })
            }
        } catch (error) {
        }
        this.setState({
            is_fetching_order_status: false
        })
    }

    fetchData = async (pageNumber, url) => {
        let { pageSize, page, filterData } = this.state

        this.setState({
            isDataFetching: true
        })
        let params = {
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize
        }
        if (filterData) {
            if (filterData.trackingNumber || filterData.recipientName || filterData.recipientPhoneNumber || filterData.senderName || filterData.senderPhoneNumber) {
                filterData = {
                    ...filterData,
                    trackingNumber: filterData.trackingNumber && filterData.trackingNumber?.trim(),
                    recipientName: filterData.recipientName && filterData.recipientName?.trim(),
                    recipientPhoneNumber: filterData.recipientPhoneNumber && filterData.recipientPhoneNumber?.trim(),
                    senderName: filterData.senderName && filterData.senderName?.trim(),
                    senderPhoneNumber: filterData.senderPhoneNumber && filterData.senderPhoneNumber?.trim(),
                }
            }
            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.error("Invalid date")
                return
            }

            if (filterData.fromDate) {
                filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
            }
            if (filterData.toDate) {
                filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
            }
            if (filterData.recipientPhoneNumber) {
                const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                filterData = { ...filterData, recipientPhoneNumber: updatedValue }
            }
            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
            } else {
                filterData = { ...filterData, 'fromDate': null, 'toDate': null }
            }

            params = {
                ...params,
                ...filterData,
                page: filterData.page ? parseInt(filterData.page) - 1 : params.page,
            }
        }

        await FetchOrderHistory(params)
            .then(response => {
                var historyMap = {}
                var titleList = []
                response.content.map(history => {
                    const dayjsDate = dayjs(history.createdDate);
                    var title = dayjsDate.date() + " " + monthMap[dayjsDate.month()] + " " + dayjsDate.year()
                    let fromLocation = history.fromAddress + ', ' + history.fromTownship?.name + ', ' + history.fromTownship?.division?.name
                    let toLocation = history.toAddress + ', ' + history.toTownship?.name + ', ' + history.toTownship?.division?.name

                    history = { ...history, createdDate: dayjsDate.format('DD MMM, YYYY hh:mm A'), lastUpdateOn: history.modifiedDate, fromLocation, toLocation }
                    if (historyMap[title]) {
                        let currentHistory = historyMap[title]
                        currentHistory = [...currentHistory, history]
                        historyMap[title] = currentHistory
                    } else {
                        historyMap[title] = [history]
                        titleList.push(title)
                    }
                })
                this.setState({
                    historyMap: historyMap,
                    titleList: titleList,
                    isDataFetching: false,
                    isSearchingData: false,
                    page: pageNumber ? pageNumber : page,
                    totalPage: response.totalElements,
                })
            })
            .catch(error => { })
    }

    search = () => {
        let { filterData } = this.state;
        const searchParams = new URLSearchParams()

        if (filterData) {
            searchParams.append('page', 1);
            if (filterData.fromDate) {
                filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
            }
            if (filterData.toDate) {
                filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
            }

            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
            } else {
                filterData = { ...filterData, 'fromDate': null, 'toDate': null }
            }

            if (filterData.purchasedInsurance) {
                filterData = { ...filterData, 'purchasedInsurance': 'true' }
            } else {
                filterData = { ...filterData, 'purchasedInsurance': null }
            }

            for (let key in filterData) {
                if (key !== 'page' && filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
                    searchParams.append(key, filterData[key])
                }
            }
        }

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = searchParams.toString();

        this.setState({
            isSearchingData: true
        })
        this.fetchData(1)
        window.history.pushState({}, '', url)
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state;

        filterData = {
            ...filterData,
            [key]: value
        }

        if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
            this.setState({
                filterData
            })
        }
        this.setState({
            filterData
        })
    }

    clearFilter = () => {
        this.formRef.current.resetFields();
        this.setState((prev) => ({
            filterData: {}
        }), () => {
            const currentUrl = new URL(window.location.href);
            currentUrl.search = '';
            window.history.replaceState({}, '', currentUrl);
            this.fetchData(1);
        })
    }

    pagination = (page, size) => {
        const urlParam = new URLSearchParams(window.location.search);
        const urlPage = urlParam.get('page');
        let newUrl;
        if (urlPage) {
            const currentUrl = window.location.href;
            let replace_url = currentUrl.toString();
            newUrl = replace_url.replace(/page=\d+/, `page=${page}`);
        } else {
            urlParam.set('page', page);
            const currentUrl = new URL(window.location.href);
            newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
        }

        window.history.pushState({}, '', newUrl)
        this.fetchData(page)
        this.setState({
            page: page - 1
        })
    }

    onSelectItem = (e, item) => {
        const { titleList, historyMap, selectedTrackingNumbers } = this.state;
        const checked = e.target.checked;

        const newHistoryMap = { ...historyMap };
        let newSelectedTrackingNumbers = [...selectedTrackingNumbers]

        titleList.forEach(title => {
            const historyList = newHistoryMap[title] || [];
            newHistoryMap[title] = historyList.map(historyItem => {
                if (historyItem.trackingNumber === item.trackingNumber) {
                    historyItem.isSelected = checked;

                    if (checked) {
                        if (!newSelectedTrackingNumbers.includes(historyItem.trackingNumber)) {
                            newSelectedTrackingNumbers.push(historyItem.trackingNumber);
                        }
                    } else {
                        newSelectedTrackingNumbers = newSelectedTrackingNumbers.filter(
                            number => number !== historyItem.trackingNumber
                        );
                    }
                }
                return historyItem;
            });
        });

        const isCheckAll = titleList.every(title =>
            newHistoryMap[title].every(historyItem => historyItem.isSelected || historyItem.orderStatus !== 'PENDING_PICK_UP')
        );

        const isCheckSpecific = newSelectedTrackingNumbers.length > 0 && !isCheckAll;

        this.setState({
            historyMap: newHistoryMap,
            selectedTrackingNumbers: newSelectedTrackingNumbers,
            isCheckAll: isCheckAll,
            isCheckSpecific: isCheckSpecific
        })

    }

    onSelectAllItem = (e) => {
        const { titleList, historyMap } = this.state
        const checked = e.target.checked;

        let newSelectedOrder = [];
        let newSelectedTrackingNumbers = []

        const newHistoryMap = { ...historyMap };

        titleList.forEach(title => {
            const historyList = newHistoryMap[title]?.map(history => {

                if (history.orderStatus === 'PENDING_PICK_UP') {
                    history.isSelected = checked;
                    if (checked) {
                        newSelectedTrackingNumbers.push(history.trackingNumber);
                    }
                }
                return history;
            })
            newHistoryMap[title] = historyList
        })

        this.setState({
            historyMap: newHistoryMap,
            selectedTrackingNumbers: checked ? newSelectedTrackingNumbers : [],
            selected_order: checked ? [...newSelectedOrder] : [],
            isCheckAll: checked,
            isCheckSpecific: !checked && newSelectedTrackingNumbers.length > 0
        });
    }

    handleParcelCancelModal = () => {
        const { selectedTrackingNumbers } = this.state

        if (selectedTrackingNumbers.length === 0) {
            message.destroy();
            message.error("Please select at least one record")
            return
        }

        this.setState({ showOrderCancelConfirmModal: true })
    }

    batchCancel = async () => {
        const { selectedTrackingNumbers } = this.state

        this.setState({ isParcelCanceling: true })
        await ApiHandler({ url: Api.batch_cancel, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData: selectedTrackingNumbers, curstomMessage: "Your order has been cancelled." })
            .then(() => {
                this.setState({
                    selectedTrackingNumbers: [],
                    isCheckAll: false,
                    isCheckSpecific: false,
                    isParcelCanceling: false,
                    showOrderCancelConfirmModal: false
                }, () => this.fetchData())
            }).catch((error) => {
                console.error("Batch cancel failed: ", error);
            })
    }

    handleExportExcel = async () => {
        let { pageSize, page, filterData } = this.state

        this.setState({
            isDownloading: true,
        })

        let params = {
            page: page - 1,
            size: pageSize
        }
        if (filterData) {
            if (filterData.trackingNumber || filterData.recipientName || filterData.recipientPhoneNumber || filterData.senderName || filterData.senderPhoneNumber) {
                filterData = {
                    ...filterData,
                    trackingNumber: filterData.trackingNumber && filterData.trackingNumber?.trim(),
                    recipientName: filterData.recipientName && filterData.recipientName?.trim(),
                    recipientPhoneNumber: filterData.recipientPhoneNumber && filterData.recipientPhoneNumber?.trim(),
                    senderName: filterData.senderName && filterData.senderName?.trim(),
                    senderPhoneNumber: filterData.senderPhoneNumber && filterData.senderPhoneNumber?.trim(),
                }
            }
            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.error("Invalid date")
                return
            }

            if (filterData.fromDate) {
                filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
            }
            if (filterData.toDate) {
                filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
            }
            if (filterData.recipientPhoneNumber) {
                const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                filterData = { ...filterData, recipientPhoneNumber: updatedValue }
            }
            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
            } else {
                filterData = { ...filterData, 'fromDate': null, 'toDate': null }
            }

            params = {
                ...params,
                ...filterData,
                page: filterData.page ? parseInt(filterData.page) - 1 : params.page,
            }
        }
        await downloadExcel(Api.order_export_excel, params, 'Order History List')
            .then(() => {
                this.setState({
                    isDownloading: false
                })
            })

    }

    render() {
        const { isDataFetching, is_fetching_order_status, historyMap, titleList, totalPage, page, filterData, pageSize, orders_status_list, isSearchingData, isCheckAll, isCheckSpecific, selectedTrackingNumbers, showOrderCancelConfirmModal, isParcelCanceling, isDownloading } = this.state
        const currentSearchUrl = window.location.search;
        const { isMobileSize } = this.props;

        const imageContainer = {
            width: 70,
            height: 70,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            position: 'relative',
        }

        return (
            <>
                <Modal
                    title={"Confirmation of Order Cancellation"}
                    centered
                    open={showOrderCancelConfirmModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    width={400}
                    className='custom-modal'
                    style={{ textAlign: 'center' }}
                >
                    <p style={{ marginBlock: 20 }}>{i18next.t("confirm_cancel")}</p>
                    <Space>
                        <NJVButton onClick={() => this.setState({ showOrderCancelConfirmModal: false })}>{i18next.t("cancel")}</NJVButton>
                        <NJVButton type='primary' style={{ background: Theme.colors.primary }} loading={isParcelCanceling} onClick={() => this.batchCancel()}>{i18next.t("confirm")}</NJVButton>
                    </Space>
                </Modal>
                <NJVCard
                    cardheaderbg={Theme.colors.primary}
                    headerheight="150px"
                    cardpadding="0px"
                    title={
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Image src={CardBg} preview={false} />
                                <MainTitle label={i18next.t('parcel_history')} style={{ position: 'absolute', top: isMobileSize ? -6 : 30, color: '#fff' }} />
                            </Col>
                        </Row>
                    }
                >
                    <Row>
                        <Col lg={2} md={2} sm={1} xs={1}></Col>
                        <Col lg={20} md={20} sm={22} xs={22}>
                            <div style={{ padding: isMobileSize ? 20 : 30, background: '#fff', borderRadius: 8, position: 'absolute', top: -70, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)', border: '1px solid #f0f0f0' }}>
                                <NJVForm ref={this.formRef} onFinish={this.clearFilter}>
                                    <Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="trackingNumber" style={{ marginBottom: 0 }}>
                                                <NJVInput
                                                    prefix={<SvgSearchOutlined width={15} height={15} color="#718EBF" />}
                                                    inputprimary="#E6EFF5"
                                                    inputborder="#E6EFF5"
                                                    // value={filterData?.trackingNumber} 
                                                    size="large" placeholder={i18next.t("enter_tracking_number")} onChange={(event) => this.updateFilterData('trackingNumber', event.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name="fromDate-toDate" style={{ marginBottom: 0 }}>
                                                <RangePicker
                                                    size="large" style={{ width: '100%', border: '1px solid #E6EFF5' }}
                                                    allowClear
                                                    // value={filterData?.['fromDate-toDate']}
                                                    onChange={(value) => this.updateFilterData('fromDate-toDate', value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name="recipientName" style={{ marginBottom: 0 }}>
                                                <NJVInput prefix={<SvgUser width={20} height={20} />} inputprimary="#E6EFF5" inputborder="#E6EFF5" bgcolor={Theme.colors.light_gray}
                                                    // value={filterData?.recipientName} 
                                                    size="large" placeholder={i18next.t("receiver_name")} onChange={(event) => this.updateFilterData('recipientName', event.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name="recipientPhoneNumber" style={{ marginBottom: 0 }}>
                                                <NJVInput prefix={<SvgUserPhone width={20} height={20} />} inputprimary="#E6EFF5" inputborder="#E6EFF5" bgcolor={Theme.colors.light_gray}
                                                    // value={filterData?.recipientPhoneNumber} 
                                                    size="large" placeholder={i18next.t("receiver_phone_number")} onChange={(event) => this.updateFilterData('recipientPhoneNumber', event.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name="orderStatus" style={{ marginBottom: 0 }}>
                                                <NJVSelect
                                                    selectborder="#E6EFF5"
                                                    selectprimary="#E6EFF5"
                                                    // value={filterData?.orderStatus}
                                                    onChange={(value) => this.updateFilterData('orderStatus', value)}
                                                    size="large"
                                                    loading={is_fetching_order_status}
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    placeholder={i18next.t("parcel_status")}
                                                    options={orders_status_list}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={6} md={6} sm={24} xs={24}>
                                            <Form.Item name="purchasedInsurance" style={{ marginBottom: 0 }}
                                                valuePropName="checked">
                                                <NJVCheckbox primarycolor='#10C200'
                                                    onChange={(e) => this.updateFilterData('purchasedInsurance', e.target.checked)}>{i18next.t("purchase_insurance")}
                                                </NJVCheckbox>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} md={4} sm={24} xs={24}>
                                            <NJVButton
                                                loading={isSearchingData}
                                                size="large"
                                                style={{ width: '100%' }}
                                                buttonprimary={Theme.colors.primary}
                                                type="primary" onClick={() => this.search()}>
                                                {i18next.t("search")}
                                            </NJVButton>
                                        </Col>
                                        <Col lg={10} md={10} sm={24} xs={24}>
                                            <NJVButton
                                                loading={isDownloading}
                                                type="primary"
                                                size="large"
                                                style={{ width: '100%' }}
                                                onClick={() => this.handleExportExcel()}
                                                buttonprimary={Theme.colors.primary}
                                            >
                                                {i18next.t("export_excel")}
                                            </NJVButton>
                                        </Col>
                                        <Col lg={4} md={4} sm={24} xs={24}>
                                            <Form.Item style={{ marginBottom: 0 }}>
                                                <NJVButton
                                                    type="text"
                                                    size="large"
                                                    style={{ width: '100%', textDecoration: 'underline', color: Theme.colors.primary }}
                                                    htmlType="submit"
                                                >
                                                    Clear Filter
                                                </NJVButton>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </NJVForm>
                            </div>
                            <Col span={24} style={{ marginTop: isMobileSize ? 480 : 230, marginBottom: 10 }}>
                                <Flex justify="space-between" align="center" gap={5}>
                                    <Flex align="center" gap={5} wrap="wrap">
                                        <Checkbox
                                            indeterminate={isCheckSpecific}
                                            checked={isCheckAll}
                                            onChange={(e) => this.onSelectAllItem(e)}
                                        >
                                            Select all
                                        </Checkbox>
                                        <Button
                                            className="icon"
                                            icon={<SvgParcelCancel width={18} height={18} />}
                                            type="primary"
                                            style={{ backgroundColor: Colors.primary }}
                                            onClick={() => this.handleParcelCancelModal()}>{i18next.t("parcel_cancel")}</Button>
                                    </Flex>
                                    <span style={{ backgroundColor: '#FBE7EC', padding: '8px 14px', borderRadius: 4, display: 'inline-block', textAlign: 'center ' }}>{selectedTrackingNumbers?.length} Selected</span>
                                </Flex>
                            </Col>
                            {
                                isDataFetching ?
                                    <Col span={24}>
                                        <Skeleton active />
                                    </Col>
                                    :
                                    <>
                                        {
                                            titleList && titleList.length !== 0 ?
                                                <>
                                                    {
                                                        titleList.map((value, index) => {
                                                            let historyList = historyMap[value]
                                                            let sortedHistoryList = historyList.sort((a, b) => {
                                                                return new Date(b.createdDate) - new Date(a.createdDate);
                                                            })
                                                            return (
                                                                <Col span={24} key={index}>
                                                                    <Row>
                                                                        <Col span={24} style={{ backgroundColor: '#f9e5ea', padding: 10, borderRadius: 5 }}>
                                                                            <span style={{ fontWeight: 'bold' }}>{value}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <List
                                                                        itemLayout="horizontal"
                                                                        dataSource={sortedHistoryList}
                                                                        renderItem={(item, index) => (
                                                                            <List.Item key={item?.trackingNumber || index} style={{ borderBottom: 0 }}>
                                                                                <Row
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        padding: 15,
                                                                                        gap: 5,
                                                                                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
                                                                                        border: '1px solid #f0f0f0',
                                                                                        borderRadius: 8
                                                                                    }}>
                                                                                    <Col span={24}>
                                                                                        <Row>
                                                                                            <Col lg={16} md={16} sm={24} xs={24}>
                                                                                                <Flex gap={20}>
                                                                                                    <Checkbox
                                                                                                        disabled={item.orderStatus !== 'PENDING_PICK_UP'}
                                                                                                        checked={item.isSelected || false}
                                                                                                        onChange={(e) => this.onSelectItem(e, item)}
                                                                                                    />
                                                                                                    <span style={{ fontSize: 16, fontWeight: 600 }}>{item.orderType}</span>
                                                                                                </Flex>
                                                                                            </Col>
                                                                                            <Col lg={8} md={8} sm={24} xs={24}>
                                                                                                <Flex justify="end" vertical gap={5}>
                                                                                                    <span style={{ fontSize: 12, color: '#999999' }}>Tracking Number</span>
                                                                                                    <Typography.Text
                                                                                                        copyable={{
                                                                                                            icon: <SvgCopyable width="16" height="16" />
                                                                                                        }}
                                                                                                        style={{ marginRight: 5 }}>{item.trackingNumber}</Typography.Text>
                                                                                                </Flex>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Link style={{ color: '#2c2c2c', width: '100%' }} to={CustomPath.order_detail} state={{ data: { id: item.trackingNumber, isFromOrderHistory: true, route: `${CustomPath.order_history}${currentSearchUrl}` } }}>
                                                                                        <Row gutter={[4, 4]}>
                                                                                            <Col lg={16} md={16} sm={24} xs={24}>
                                                                                                <Flex gap={20} wrap="wrap">
                                                                                                    <Flex vertical gap={10}>
                                                                                                        <div style={{ ...imageContainer, background: '#F9E5EA', border: item.orderStatus === 'DELIVERED' && '3.5px solid #10C200' }}>
                                                                                                            <Image src={imageMap[item.orderStatus]} alt={`${item.orderStatus} image`} preview={false} style={{ objectFit: 'cover', position: 'relative' }} />
                                                                                                            {
                                                                                                                item.orderStatus === 'DELIVERED' && <CheckCircleFilled
                                                                                                                style={{
                                                                                                                    color: Theme.colors.primary,
                                                                                                                    position: 'absolute',
                                                                                                                    bottom: 8,
                                                                                                                    left: 47,
                                                                                                                    fontSize: 20,
                                                                                                                }}
                                                                                                            />
                                                                                                            }
                                                                                                        </div>
                                                                                                        <NJVCustomOrderStatusTag orderStatus={item.orderStatus} value={item.ninjaCoreOrderStatus} />
                                                                                                    </Flex>
                                                                                                    <div>
                                                                                                        <span style={{ fontWeight: 600 }}>{item.recipientName}</span><br />
                                                                                                        <span>{item.recipientPhoneNumber}</span>
                                                                                                        <NJVDeliveryStep from={item.fromLocation} to={item.toLocation} status={item.orderStatus} />
                                                                                                    </div>
                                                                                                </Flex>
                                                                                            </Col>
                                                                                            <Col lg={8} md={8} sm={24} xs={24} style={{ display: 'flex', alignItems: 'end' }}>
                                                                                                {
                                                                                                    item.lastUpdateOn ?
                                                                                                        <Flex vertical>
                                                                                                            <span><CalendarOutlined style={{ color: Theme.colors.primary, marginRight: 10 }} /> Last Updated On : {dayjs(item.lastUpdateOn,).format('YYYY-MMM-DD')}</span>
                                                                                                            <span><ClockCircleOutlined style={{ color: Theme.colors.primary, marginRight: 10 }} />{dayjs(item.lastUpdateOn).format('hh:mm A')}</span>
                                                                                                        </Flex>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Link>
                                                                                </Row>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                    <Col span={24} style={{ marginBlock: 10, display: 'flex', justifyContent: 'end' }}>
                                                        <Pagination
                                                            showSizeChanger={false}
                                                            current={page}
                                                            defaultPageSize={pageSize}
                                                            total={totalPage}
                                                            onChange={this.pagination} />
                                                    </Col>
                                                </>
                                                :
                                                <Row style={{ marginTop: 50, justifyContent: 'center', width: '100%' }}>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Image
                                                            width={200}
                                                            preview={false}
                                                            src={empty_history_image} />
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                        {
                                                            filterData ?
                                                                <div style={{ fontSize: 17 }}>
                                                                    No order found for your search request.
                                                                </div>
                                                                :
                                                                <>
                                                                </>

                                                        }
                                                    </Col>
                                                </Row>

                                        }

                                    </>

                            }
                        </Col>
                    </Row >

                </NJVCard >
            </>
        )
    }
}

export default compose(withRouter)(ParcelHistory);