import { compose } from "@reduxjs/toolkit";
import { Col, message, Row, Skeleton, Tag } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { MainTitle, NJVButton, NJVDatePicker, NJVSelect, NJVTable } from "../../components/core-component";
import { SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined } from "../../components/custom-svgIcon";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, InvoiceDownloadByFormat } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class PublicInvoicePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalPagination: 0,
            page: 1,
            pageSize: 20,
            last_invoice: null,
            filterData: {},
            isDataFetching: false,
            isFilterAll: false,
            isFilterByDateRange: false,
            defaultFilter: 1,
            activeKey: 1
        }
    }

    componentDidMount() {
        this.fetchInvoice()
    }

    fetchInvoice = (pageNumber) => {
        const { page, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            "page": pageNumber ? pageNumber - 1 : page - 1,
            "size": pageSize,
            "startDate": "",
            "endDate": "",
        }
        ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                if (response?.data?.items) {
                    this.setState({
                        data: response.data.items,
                        last_invoice: response.data.items[0],
                        page: pageNumber ? pageNumber : 1,
                        totalPagination: response.data.totalRecords
                    })
                }
            })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            })
    }

    downloadInvoice = async (type, invoice) => {
        try {
            const param = {
                format: type
            }
            const requestBody = {
                "guid": invoice.guid
            }
            // let contentType = 'application/pdf'
            await InvoiceDownloadByFormat(Api.invoice_download, requestBody, param, invoice.merchantName, this.props)

        } catch (error) {
        }
    }

    filterBySpecific = (value) => {
        this.setState({
            defaultFilter: value
        })
        if (value === 1) {
            this.fetchInvoice()
        } else if (value === 2) {
            const endDate = dayjs();
            const startDate = endDate.subtract(7, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        } else if (value === 3) {
            const endDate = dayjs();
            const startDate = endDate.subtract(30, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        }
    }

    filterByDateRage = async (startDate, endDate, pageNumber) => {
        const { page, pageSize } = this.state

        try {
            this.setState({
                isDataFetching: true,
                // filterData: { 'startDate': startDate, 'endDate': endDate },
            })
            const requestParams = {
                "page": pageNumber ? pageNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": startDate || '',
                "endDate": endDate || '',
            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    isFilterAll: false,
                    page: pageNumber ? pageNumber : 1,
                    isFilterByDateRange: true,
                    totalPagination: response.data.totalRecords,
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }
        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: false,
                isFilterByDateRange: true,
            })
        }
    }

    search = () => {
        const { filterData } = this.state

        if (filterData) {

            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.destroy()
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.destroy()
                message.error("Invalid date")
                return
            }
            const startDate = filterData?.fromDate?.format("YYYY-MM-DD")
            const endDate = filterData?.toDate?.format("YYYY-MM-DD")
            this.setState({
                defaultFilter: null
            })
            this.filterByDateRage(startDate, endDate)
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        const { isFilterAll, isFilterByDateRange, filterData } = this.state
        if (isFilterAll) {
            this.fetchInvoice(pageNumber);
        } else if (isFilterByDateRange && filterData && filterData.fromDate && filterData.toDate) {
            this.filterByDateRage(filterData.fromDate, filterData.toDate, pageNumber)
        }
    }

    render() {
        const { data, isDataFetching, totalPagination, page, pageSize, last_invoice, defaultFilter } = this.state

        const columns = [
            {
                title: "No.",
                width: 30,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Invoice Date',
                key: 'invoiceDate',
                render: (invoice) => {
                    let invoiceDate = ""
                    if (invoice && invoice.invoiceDate) {
                        const convertedInvoiceDate = dayjs(invoice.invoiceDate, 'YYYY-MM-DD');
                        if (convertedInvoiceDate) {
                            invoiceDate = convertedInvoiceDate.format('DD-MMM-YYYY')
                        }
                    }
                    return <span>{invoiceDate}{last_invoice.invoiceDate === invoice.invoiceDate ? <Tag color='#B19478' style={{ marginLeft: 10 }}>Latest</Tag> : ''}</span>
                }
            }, {
                title: 'PDF',
                width: 50,
                align: 'center',
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', invoice)}><SvgPdfOutlined width={23} height={23} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Image',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', invoice)}><SvgPngOutlined width={23} height={23} color={Colors.primary} /></NJVButton>
                ),
            },
            {
                title: 'Excel',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', invoice)}><SvgExcelOutlined width={23} height={23} color={Colors.primary} /></NJVButton>
                ),
            }
        ]

        return (
            <Row style={{ marginTop: 20 }}>
                <Col lg={3} md={1} xs={1} sm={1} />
                <Col lg={18} md={22} xs={22} sm={22}>
                    <Row>
                        <Col span={24}>
                            <MainTitle label={'Invoice'} />
                        </Col>
                        {
                            last_invoice ?
                                <Col span={24} style={{ marginTop: 20 }}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24} style={{ fontWeight: 600 }}>Invoice No: {last_invoice.invoiceNo}</Col>
                                        <Col span={24} xs={24}>Invoice Date : {last_invoice.invoiceDate}</Col>
                                        <Col span={24} >Value : <span style={{ color: 'rgba(16, 194, 0, 1)', fontWeight: '700', fontSize: 15 }}>{last_invoice.transferAmount}</span></Col>
                                    </Row>
                                </Col>
                                : <></>
                        }
                        <Col span={24} style={{ marginBottom: 20, marginTop: 20 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <NJVSelect
                                        size="large"
                                        style={{
                                            width: '100%'
                                        }}
                                        selectborder="#E6EFF5"
                                        selectprimary="#E6EFF5"
                                        placeholder="Select"
                                        value={defaultFilter}
                                        onChange={(value) => this.filterBySpecific(value)}
                                        options={[
                                            {
                                                'key': 1,
                                                'label': <>{i18next.t('order_billing')}</>,
                                                'value': 1
                                            }, {
                                                'key': 2,
                                                'label': 'Last 7 days',
                                                'value': 2
                                            }, {
                                                'key': 3,
                                                'label': 'Last 30 days',
                                                'value': 3
                                            }
                                        ]}
                                    />
                                </Col>

                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <NJVDatePicker
                                        datepickerborder="#E6EFF5"
                                        datepickerprimary="#E6EFF5"
                                        onChange={(value) => this.updateFilterData('fromDate', value)}
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={i18next.t("from_date")} />
                                </Col>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <NJVDatePicker
                                        datepickerborder="#E6EFF5"
                                        datepickerprimary="#E6EFF5"
                                        onChange={(value) => this.updateFilterData('toDate', value)}
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={i18next.t("to_date")} />
                                </Col>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <NJVButton
                                        buttonprimary={Theme.colors.primary}
                                        size="large"
                                        style={{ width: '100%' }}
                                        type="primary" onClick={() => this.search()}>
                                        {i18next.t("search")}
                                    </NJVButton>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            {
                                isDataFetching ?
                                    <Skeleton />
                                    :
                                    <NJVTable
                                        headerbgcolor="#F7F7F7"
                                        headerborderradius='0px'
                                        cellinline="6px"
                                        cellblock="6px"
                                        tableborder="#EAEAEA"
                                        rowKey={(record) => record.id}
                                        style={{ borderRadius: 0 }}
                                        pagination={{
                                            position: 'top' | 'bottom',
                                            total: totalPagination,
                                            current: page,
                                            onChange: this.handlePaginationChange,
                                            defaultPageSize: pageSize,
                                            showSizeChanger: false
                                        }}
                                        columns={columns} dataSource={data} />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(PublicInvoicePage)