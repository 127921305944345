import { compose } from '@reduxjs/toolkit';
import { Col, Image, Row, Skeleton, Typography } from 'antd';
import React, { Component } from 'react';
import { NJVCard } from '../../components/core-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandlerNoAuth } from '../../network/network-manager';
import withRouter from '../../network/with-router';

const { Text } = Typography;
// const noImage = 'https://d1jejw4ssai0l5.cloudfront.net/images/product/NVM000001/1717590261_n4.png'
const empty_product_image = require('../../asset/no-data-found.png')

class Products extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isFetchingData: false,
            notDataFound: false,
            invalidImageUrl: {}
        }
    }

    componentDidMount = () => {
        this.fetchProducts();
    }


    fetchProducts = async () => {
        this.setState({
            isFetchingData: true
        })
        // await ApiHandlerNoAuth(Api.public_get_products, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, this.props.params.uniqueId)
        await ApiHandlerNoAuth({url: Api.public_get_products,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON,specificId: this.props.params.uniqueId})
            .then(response => {
                this.setState({
                    isFetchingData: false,
                    notDataFound: false,
                })
                response.content.forEach(item => {
                    this.setState(prevState => ({
                        items: [...prevState.items, item]
                    }))
                });
            })
            .catch(error => {
                this.setState({
                    isFetchingData: false,
                    notDataFound: true
                })
            })
    }

    render() {
        let { items, isFetchingData, notDataFound, invalidImageUrl } = this.state;

        return (
            <Row gutter={[20, 20]}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
                    <span>Home</span>
                </Col>
                {
                    isFetchingData ? <Skeleton active />
                        :
                        <>
                            {
                                notDataFound ?
                                    <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
                                        <Image
                                            width={200}
                                            preview={false}
                                            src={empty_product_image} />
                                        <span style={{ marginTop: 20, fontSize: 18 }}>သင်ရှာဖွေသောလိပ်စာမှားယွင်းနေပါသည်။ Online Shopနှင့်ပြန်လည်စစ်ဆေးပါ။</span>
                                    </Col>
                                    :
                                    <>
                                        {
                                            items.length === 0 ?
                                                <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
                                                    <Image
                                                        width={200}
                                                        preview={false}
                                                        src={empty_product_image} />
                                                    <span style={{ marginTop: 20, fontSize: 18 }}>Sorry, no products are available.</span>
                                                </Col>
                                                : (
                                                    <>
                                                        {
                                                            items.map((item) => {
                                                                return (
                                                                    <Col lg={6} md={8} sm={12} xs={12} key={item.id}>
                                                                        <NJVCard
                                                                            cardBgColor="#fff"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => this.props.navigate(`/public/store/${this.props.params.uniqueId}/product-detail`, { state: { productId: item.id, invalidImageUrl: invalidImageUrl[item.id] } })}
                                                                            cover={
                                                                                <div className='img-container' style={{ position: 'relative' }}>
                                                                                    <img alt="product"
                                                                                        src={item.imageUrls[0]}
                                                                                        onError={() => {
                                                                                            invalidImageUrl[item.id] = true
                                                                                            this.setState(() => ({
                                                                                                invalidImageUrl
                                                                                            }))
                                                                                        }}
                                                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, position: 'absolute' }} />
                                                                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', objectFit: 'cover', borderRadius: 8, position: 'absolute', backgroundColor: '#f1f1f1', zIndex: invalidImageUrl[item.id] ? 100 : -100 }} >
                                                                                        No Image
                                                                                    </div>
                                                                                </div>
                                                                            }>
                                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                <Text style={{ fontSize: 17, fontWeight: 600 }}>{item.name}</Text>
                                                                                <Text style={{ textOverflow: 'ellipsis', color: 'gray', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.description}</Text>
                                                                                <Text>
                                                                                    {item.price} MMK
                                                                                </Text>
                                                                            </div>
                                                                        </NJVCard>
                                                                    </Col>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </>
                                                )
                                        }
                                    </>
                            }

                        </>
                }
            </Row>
        )
    }
}

export default compose(withRouter)(Products)