import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Collapse, ConfigProvider, DatePicker, Flex, Form, Input, InputNumber, Pagination, Radio, Row, Select, Table, Tabs, Typography, Upload } from 'antd';
import Lottie from 'lottie-react';
import React, { forwardRef } from "react";
import NoDataFoundAnimation from '../animate-lottie/lottie-not-data-found.json';
import { SvgArrowLeftFilled, SvgSearchOutlined } from './custom-svgIcon';
import Theme from './theme';

const { Title } = Typography;

export const NJVSpacer = ({ height, width }) => {
    return (
        <div style={{ height: height, width: width, display: 'inline-block' }}></div>
    )
}

export const NJVAddButton = ({ handleClick }) => {
    return <Button
        type="primary"
        icon={<PlusCircleFilled />}
        shape="round"
        onClick={handleClick}
    >
        Create
    </Button>
}

export const NJVSearchButton = ({ onClick }) => {
    return <Button
        type="primary"
        icon={<SearchOutlined />}
        shape="round"
        onClick={onClick}
    >
        Search
    </Button>
}

export const NJVBackButtonWithIcon = () => {
    return <Button
        type="primary"
        shape="circle"
        size="large"
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
            backgroundColor: Theme.colors.light_gray_2
        }}
        icon={<SvgArrowLeftFilled width={22} height={22} color={'#000'} />}
    >
    </Button>
}

export const NJVSelect = ({ children, ...selectProps }) => {
    const { bgcolor, selectprimary, selectborder } = selectProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                colorBorder: selectborder || '#C2002F66',
                colorPrimary: selectprimary || '#C2002F66',
                colorPrimaryHover: selectprimary || '#C2002F66',
                controlHeightLG: '40px',
                // fontFamily: 'Segoe UI'
            },
            components: {
                Select: {
                    // borderRadius: '9px',
                    optionPadding: '4px 12px',
                    optionSelectedBg: 'rgb(128, 128, 128)',
                    optionSelectedColor: '#fff',
                    optionHeight: 32
                }
            }
        }}>
            <Select {...selectProps} >{children}</Select>
        </ConfigProvider>
    )
}

export const NJVDatePicker = ({ ...datepickerProps }) => {
    const { bgcolor, datepickerprimary, datepickerborder } = datepickerProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBorder: datepickerborder || '#C2002F66',
                colorPrimaryHover: datepickerprimary || '#C2002F66',
                cellWidth: 36,
                colorPrimary: datepickerprimary || '#C2002F66',
            },
            components: {
                DatePicker: {
                    colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                    // activeShadow: '0 0 0 1px rgb(128, 128, 128)',
                    // borderRadius: '9px',
                    activeBorderColor: '#fff'
                },
            }
        }}>
            <DatePicker {...datepickerProps} />
        </ConfigProvider>
    )
}

export const NJVCard = ({ children, ...cardProps }) => {
    const { cardpadding, cardbgcolor, cardheaderbg, cardborder, extracolor, headerheight } = cardProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${cardbgcolor ? cardbgcolor : Theme.colors.white}`,
                // boxShadowTertiary: `${cardshadow ? cardshadow : 'none'}`,

            },
            components: {
                Card: {
                    extraColor: extracolor || 'rgba(0, 0, 0, 0.88)',
                    headerHeight: headerheight || 56,
                    headerBg: cardheaderbg || 'transparent',
                    padding: cardpadding || '15px',
                    paddingLG: cardpadding || '15px',
                }
            }
        }}>
            <Card {...cardProps} bordered={cardborder ? cardborder : false}>{children}</Card>
        </ConfigProvider>
    )
}

export const NJVTable = ({ ...tableProps }) => {
    const { headerbgcolor, headerborderradius, tableborder, cellblock, cellinline } = tableProps;

    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#fff'
            },
            components: {
                Table: {
                    headerBg: headerbgcolor ? headerbgcolor : 'transparent',
                    // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
                    cellPaddingBlock: cellblock || 16,
                    cellPaddingInline: cellinline || 16,
                    borderColor: tableborder || 'transparent',
                    headerBorderRadius: headerborderradius ? headerborderradius : '8px',
                }
            }
        }}>
            <Table {...tableProps} />
        </ConfigProvider>
    )
}

export const NJVReportTable = ({ ...tableProps }) => {
    return (
        <ConfigProvider theme={{

            components: {
                Table: {
                    headerBg: 'transparent',
                    headerColor: '#fff',
                    // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
                    cellPaddingBlock: '5px',
                    borderColor: 'transparent'
                }
            }
        }}>
            <Table className="custom-table" {...tableProps} />
        </ConfigProvider>
    )
}

export const NJVEmptyData = ({ ...props }) => {
    const { label } = props
    const message = label ? label : 'Oops! There’s nothing here right now. Try refreshing or come back later.'
    return <Card>
        <Lottie
            style={{ height: '200px' }}
            animationData={NoDataFoundAnimation}
            loop={true}
            autoplay={true}
        />
        <div style={{ textAlign: 'center', fontSize: 16, fontWeight: '600', color: 'gray' }}>
            {message}
        </div>

    </Card>
}

export const NJVInput = ({ ...inputProps }) => {
    const { ispasswordinput, istextareainput, issearchinput, inputprimary, inputborder, isPlaceholderSize } = inputProps;

    return (
        <ConfigProvider theme={{
            token: {
                fontSize: isPlaceholderSize || 14,
                // fontFamily: 'Segoe UI',
                colorPrimary: inputprimary || '#C2002F66',
                colorPrimaryHover: inputprimary || '#C2002F66',
                colorBorder: inputborder || '#C2002F66',
            },
            components: {
                Input: {
                    // paddingBlockLG: 5
                    activeBorderColor: inputborder || '#C2002F66',
                    hoverBorderColor: inputborder || '#C2002F66',
                    activeShadow: inputborder || '#C2002F66',
                }
            }
        }}>
            {
                ispasswordinput ? <Input.Password {...inputProps} /> : inputProps.isNumberInput ? <InputNumber {...inputProps} /> :
                    istextareainput ? <Input.TextArea {...inputProps} /> :
                        issearchinput ? <Input.Search {...inputProps} /> : <Input {...inputProps} />
            }
        </ConfigProvider>
    )
}

export const NJVButton = ({ children, ...buttonProps }) => {
    const { buttonprimary } = buttonProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorPrimary: buttonprimary || '#1677ff',
                colorBorder: buttonprimary || '#C2002F66',
                colorPrimaryHover: buttonprimary || '#C2002F66',
            },
            components: {
                Button: {
                    colorPrimary: buttonprimary || '#1677ff',
                }
            }
        }}>
            <Button {...buttonProps}>{children}</Button>
        </ConfigProvider>
    )
}
export const NJVForm = forwardRef(({ children, ...formProps }, ref) => (
    <ConfigProvider theme={{
        token: {
            fontWeight: 'bold',
        },
        components: {
            Form: {
                verticalLabelPadding: formProps.labelpadding || '0 0 1px'
            }
        }
    }}>
        <Form {...formProps} ref={ref}>{children}</Form>
    </ConfigProvider>
));

export const NJVBoldFont = ({ children }) => <Typography.Text style={{ margin: 0, fontWeight: 600 }}>{children}</Typography.Text>

export const NJVRadioGroup = ({ children, ...groupprops }) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorPrimary: '#C2002F66',
                colorBorder: '#C2002F66',
                colorPrimaryHover: '#C2002F66',
            }
        }}>
            <Radio.Group {...groupprops}>
                {children}
            </Radio.Group>
        </ConfigProvider>
    )
}

export const NJVUpload = ({ ...uploadProps }) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: Theme.colors.light_primary,
                    colorBorder: '#C2002F66',
                    colorPrimaryHover: '#C2002F66',
                    colorFillAlter: Theme.colors.light_primary
                }
            }}>
            <Upload {...uploadProps} />
        </ConfigProvider>
    )
}

export const NJVCheckbox = ({ ...checkboxProps }) => {
    const { primarycolor } = checkboxProps;
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primarycolor || Theme.colors.light_primary,
                }
            }}>
            <Checkbox {...checkboxProps} />
        </ConfigProvider>
    )
}

export const NJVCollapse = ({ ...collapseProps }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        headerBg: '#fff'
                    }
                }
            }}
        >
            <Collapse {...collapseProps} />
        </ConfigProvider>
    )
}

export const NJVTabs = ({ ...tabsProps }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        inkBarColor: Theme.colors.primary,
                        itemSelectedColor: Theme.colors.primary,
                        itemHoverColor: Theme.colors.primary
                    }
                }
            }}
        >
            <Tabs {...tabsProps} />
        </ConfigProvider>
    )
}

export const MainTitle = ({ label, style }) => <Title level={4} style={{ marginBlock: 0, ...style }}>{label}</Title>

export const ModalTitle = ({ label, style }) => <Title level={5} style={{ marginBlock: 0, ...style }}>{label}</Title>

export const NJVSearchBoxCard = ({ placeholder, buttonname, handleFilter, search, showSearch }) => {
    return (
        <div style={{ border: '1px solid #E6EFF5', padding: '10px 15px', marginBottom: 10, borderRadius: 10, background: '#fff' }}>
            <Form onFinish={search}>
                <Row>
                    <Col lg={20} md={20} sm={18} xs={18}>
                        <Form.Item name="searchValue" style={{ marginBottom: 0 }}>
                            <Flex align='center' justify='center' gap={5}>
                                <SvgSearchOutlined width={25} height={25} color="#718EBF" />
                                <NJVInput
                                    inputborder="#fff"
                                    size='large'
                                    placeholder={placeholder} onChange={handleFilter} />
                            </Flex>
                        </Form.Item>
                    </Col>
                    {
                        showSearch &&
                        <Col lg={4} md={4} sm={6} xs={6}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <NJVButton size="large" type="primary"
                                    block
                                    htmlType="submit"
                                    buttonprimary={Theme.colors.primary}>{buttonname}</NJVButton>
                            </Form.Item>
                        </Col>
                    }
                </Row>
            </Form>
        </div>
    )
}
