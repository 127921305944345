import { compose } from "@reduxjs/toolkit";
import { Col, Flex, message, Row } from "antd";
import Lottie from "lottie-react";
import React from "react";
import SuccessAnimation from '../../animate-lottie/lottie-payment-success.json';
import withRouter from '../../network/with-router';
import Theme from "../../components/theme";
import { NJVButton } from "../../components/core-component";
class KBZReturnPage extends React.Component {

    handleRedirect = () => {
        try {
            const appLink = "nvmshipper://open?showStartAds=false&isCheckPaymentReceived=true";
            window.location.href = appLink;
        } catch (e) {
            message.error(e)
        }
    }
    render() {
        return (
            <Flex justify="center" >
                <Row>
                    <Col span={2} />
                    <Col span={20}>
                        <Row>
                            <Col span={24}>
                                <Lottie
                                    style={{ height: '300px' }}
                                    animationData={SuccessAnimation}
                                    loop={false}
                                    autoplay={true}
                                />
                            </Col>
                            <Col span={24}>
                                <Flex justify="center" style={{ marginTop: -40 }} >
                                    <span style={{ fontSize: 20, fontWeight: '600', color: Theme.colors.success }}>Payment Successful!</span>
                                </Flex>
                            </Col>
                            <Col span={24} style={{ marginTop: 30 }}>
                                <NJVButton buttonprimary={Theme.colors.primary} type="primary" size="large" shape="round" style={{ width: '100%' }}
                                    onClick={() => this.handleRedirect()}
                                >
                                    Go To Ninja Van Myanmar
                                </NJVButton>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Flex>
        )
    }
}
export default compose(withRouter)(KBZReturnPage)