import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, Form, Image, Row, Switch, Upload, message } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { NJVButton, NJVInput } from '../../components/core-component';
import Theme from '../../components/theme';
import CustomPath from '../../routes/custom-path';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import Api from '../../network/api';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/with-router';

class AddProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewOpen: false,
            previewImage: '',
            fileList: [],
            isStock: false,
            isAddingProduct: false
        }
        this.formRef = React.createRef();
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
        this.formRef.current?.setFieldsValue({'photoUrl': fileList})
    };

    handleUserChange = (key, value) => {
        if (key === 'stock') {
            this.setState({
                isStock: !this.state.isStock
            })
        }
    }

    onFinish = async (values) => {
        const {photoUrl, productName, description, category, price, quantity, status } = values;
        this.setState({
            isAddingProduct: true
        })
        try {
            const formData = new FormData()
            photoUrl.map(image => formData.append('images', image.originFileObj))
            formData.append('name', productName)
            formData.append('description', description)
            formData.append('category', category)
            formData.append('price', price)
            formData.append('quantity', quantity ? quantity : 0)
            formData.append('status', status === true ? 'ACTIVE' : 'INACTIVE')
            let response = await ApiHandler({ url: Api.product, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            if (response.status === 200) {
                this.setState({
                    isAddingProduct: false
                })
            }
        } catch (error) {
        }
        message.success('Form submitted successfully!');
    }

    render() {
        const { fileList, previewImage, previewOpen, isStock, isAddingProduct } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, marginInline: 10 }}>Upload</div>
            </div>
        );

        return (
            <Row style={{ marginBlock: 20 }}>
                <Col lg={6} md={4} sm={1} xs={1} />
                <Col lg={12} md={16} sm={22} xs={22}>
                    <Row>
                        <Col span={24}>
                            <Card
                                title='Add Product'
                                style={{ marginTop: 15, backgroundColor: Theme.colors.secondary }}
                                extra={<NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.store)}>{i18next.t('back')}</NJVButton>
                                }
                            >
                                <Form onFinish={this.onFinish} layout="vertical" initialValues={{
                                    stock: false, status: true
                                }} ref={this.formRef}>
                                    <Form.Item name="photoUrl" label={"Add Product Photo"} 
                                    valuePropName="fileList" getValueFromEvent={({fileList}) => fileList}
                                    rules={[{ required: true, message: 'Photo is required!'}]}
                                    >
                                        <Upload
                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                            beforeUpload={() => false}
                                        >
                                            {fileList.length >= 3 ? null : uploadButton}
                                        </Upload>
                                        {previewImage && (
                                            <Image
                                                wrapperStyle={{
                                                    display: 'none',
                                                }}
                                                preview={{
                                                    visible: previewOpen,
                                                    onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                    afterOpenChange: (visible) => !visible && this.setState({ previewOpen: '' }),
                                                }}
                                                src={previewImage}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Category" name="category" rules={[
                                        {
                                            required: true,
                                            message: 'Category is required!'
                                        }
                                    ]}>
                                        <NJVInput size="large" />
                                    </Form.Item>
                                    <Form.Item label="Name" name="productName" rules={[
                                        {
                                            required: true,
                                            message: 'Name is required!'
                                        }
                                    ]}>
                                        <NJVInput size="large" />
                                    </Form.Item>
                                    <Form.Item label="Description" name="description" rules={[
                                        {
                                            required: true,
                                            message: 'Description is required!'
                                        }
                                    ]}>
                                        <NJVInput size="large" />
                                    </Form.Item>
                                    <Form.Item label="Price" name="price" rules={[
                                        {
                                            required: true,
                                            message: 'Price is required!'
                                        }
                                    ]}>
                                        <NJVInput size="large" />
                                    </Form.Item>
                                    <Form.Item label="Stock" name="stock" >
                                        <Switch onChange={(checked) => this.handleUserChange('stock', checked)} />
                                    </Form.Item>
                                    {
                                        isStock ? <Form.Item label="Quantity" name="quantity">
                                            <NJVInput size="large" />
                                        </Form.Item> : <></>
                                    }
                                    <Form.Item label="Active" name="status" valuePropName="checked">
                                        <Switch onChange={(checked) => this.handleUserChange('status', checked)} />
                                    </Form.Item>
                                    <Form.Item>
                                        <NJVButton htmlType="submit" loading={isAddingProduct} type="primary" style={{ backgroundColor: Theme.colors.primary }}>Save</NJVButton>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(AddProduct)