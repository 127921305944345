import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Button, Col, Divider, Row, Skeleton, Typography, message } from 'antd';
import React, { Component } from 'react';
import { NJVButton } from '../../components/core-component';
import { SvgCartAddFilled, SvgMinusOutlined, SvgPlusOutlined } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandlerNoAuth } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import { addToCart } from '../../redux/cart-slice';
import CustomPath from '../../routes/custom-path';

const { Title } = Typography;
const buttonStyle = {
  backgroundColor: '#f5f5f5',
  width: 120,
  borderRadius: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  cursor: 'pointer',
  height: 40
}

class ProductDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      productId: this.props.location?.state.productId || null,
      invalidImageUrl: this.props.location?.state.invalidImageUrl || null,
      productDetail: null,
      quantity: 1,
      selectedImageUrl: '',
      isSelected: false,
      isFetchingData: false,
      invalidImage: false
    }
  }

  componentDidMount = () => {
    this.fetchProductDetail()
    const { invalidImageUrl } = this.state;
    if (invalidImageUrl) {
      this.setState({
        invalidImage: true
      })
    }
  }

  fetchProductDetail = async () => {
    this.setState({
      isFetchingData: true,
    })
    // await ApiHandlerNoAuth(Api.public_get_product_detail, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, this.state.productId)
    await ApiHandlerNoAuth({url: Api.public_get_product_detail,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON,specificId: this.state.productId})
      .then(response => {
        if (response.imageUrls) {
          this.setState({
            isFetchingData: false,
            productDetail: response,
            selectedImageUrl: response.imageUrls[0],
            isSelected: true,
          })
        }
      }).catch(error => {
      })
  }

  handleIncrease = () => {
    this.setState((prev) => ({
      quantity: prev.quantity < this.state.productDetail.quantity ? prev.quantity + 1 : prev.quantity
    }))
  }

  handleDecrease = () => {
    this.setState((prev) => ({
      quantity: prev.quantity > 1 ? prev.quantity - 1 : 1
    }))
  }

  handleAddtoCart = () => {
    const { productDetail, quantity } = this.state;
    const { cartItems } = this.props;

    const existingItem = Object.values(cartItems)[0]?.find(item => item.id === productDetail.id)
    const newQuantity = existingItem ? existingItem.quantity + quantity : quantity;

    if (newQuantity > productDetail?.quantity) {
      message.destroy();
      message.error('The quantity exceed the available stock')
      return
    }

    const data = {
      id: productDetail.id,
      name: productDetail?.name,
      imageUrl: productDetail?.imageUrls[0],
      stock: productDetail?.quantity,
      quantity: newQuantity,
      price: productDetail?.price,
    }
    this.props.dispatch(addToCart({ key: this.props.params.uniqueId, data }))
    message.destroy()
    message.success('Successfully added to the cart')
  }

  handleImage = (e) => {
    const url = e.target.src;
    this.setState({
      selectedImageUrl: url,
      isSelected: true
    })
  }

  render() {
    const { productDetail, isFetchingData, selectedImageUrl, isSelected, quantity, invalidImage } = this.state;

    return (
      <Row gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
          <Breadcrumb items={[
            {
              title: <a href={`${CustomPath.products}/${this.props.params.uniqueId}`}>Home</a>
            },
            {
              title: 'Product Detail'
            }
          ]} />
          {/* <Link to={`${CustomPath.products}/${this.props.params.uniqueId}`}><span style={{ color: '#000' }}>Home</span></Link>
          <div style={{ width: 8, height: 8, borderRadius: 50, backgroundColor: 'green', marginInline: 10, marginTop: 3 }} />
          <span>Product Detail</span> */}
        </Col>
        <>
          {
            isFetchingData ? <Skeleton /> : (

              <>
                <Col lg={24} md={0} sm={0} xs={0}>
                  <Row gutter={[16, 5]}>
                    {
                      productDetail?.imageUrls.length > 1 &&
                      <Col span={2}>
                        <Row gutter={[16, 5]}>
                          {
                            productDetail.imageUrls.map((url, index) => (
                              <Col key={index}>
                                <div style={{ height: 80, width: 80, objectFit: 'cover', marginBottom: 5 }}>
                                  <img src={url} alt="product"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, border: isSelected && (url === selectedImageUrl) ? '2px solid #c2002f' : '1px solid #f1f1f1', cursor: 'pointer' }}
                                    onClick={(event) => this.handleImage(event)} />
                                </div>
                              </Col>
                            ))
                          }
                        </Row>
                      </Col>
                    }
                    <Col span={11}>
                      <div style={{ height: 400, width: '100%', padding: 'relative' }}>
                        <img
                          src={selectedImageUrl}
                          // onError={(e) => {
                          //   e.target.onerror = null; 
                          //   e.target.src = noImage;
                          // }}
                          alt='item name'
                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, marginBottom: 10, overflow: 'hidden', border: '1px solid #f1f1f1', position: 'absolute' }}
                        />
                        {
                          invalidImage && <div style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, marginBottom: 5, backgroundColor: '#f1f1f1', zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            No Image
                          </div>
                        }
                      </div>
                    </Col>
                    <Col span={11} style={{ paddingLeft: 20 }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 400 }}>
                        <div>
                          <Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>{productDetail?.name}</Title>
                          <Title level={4} style={{ marginTop: 0, marginBottom: 5 }}>{productDetail?.photographer}</Title>
                          <div style={{ marginBottom: 20 }}>{productDetail?.description}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <span style={{ fontSize: 18, fontWeight: 'bold', }}>{productDetail?.price}  MMK</span>
                          <br />
                          <span style={{ fontSize: 17, color: 'gray' }}>Instock {productDetail?.quantity}</span>
                          <Divider />
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={buttonStyle}>
                              <NJVButton
                                className='center-element'
                                icon={<SvgMinusOutlined width={20} height={20} />}
                                type='text'
                                shape='circle'
                                onClick={this.handleDecrease}
                              />
                              <span>{productDetail?.quantity === 0 ? 0 : quantity}</span>
                              <NJVButton
                                className='center-element'
                                icon={<SvgPlusOutlined width={20} height={20} />}
                                type='text'
                                shape='circle'
                                onClick={this.handleIncrease}
                              />
                            </div>
                            <Button disabled={productDetail?.quantity === 0} type="primary" size='large' shape='round' style={{ backgroundColor: productDetail?.quantity !== 0 ? Theme.colors.primary : '#f1f1f1', height: 40, paddingInline: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }} onClick={this.handleAddtoCart}>
                              <SvgCartAddFilled width={18} height={18} color="#fff" />Add to Cart
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={0} md={24} sm={24} xs={24}>
                  <Row gutter={[16, 5]}>
                    <Col>
                      <div style={{ width: '100%', height: 300, objectFit: 'cover' }}>
                        <img src={selectedImageUrl} alt="product" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, marginBottom: 10, overflow: 'hidden' }} />
                      </div>
                    </Col>
                    {
                      productDetail?.imageUrls.length > 1 && <Col>
                        <Row gutter={[16, 5]}>
                          {
                            productDetail.imageUrls.map((url, index) => (
                              <Col key={index}>
                                <div style={{ height: 80, width: 80, objectFit: 'cover', marginBottom: 5 }}>
                                  <img src={url} alt='product' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, border: isSelected && (url === selectedImageUrl) ? '2px solid #c2002f' : '1px solid #f1f1f1', cursor: 'pointer' }} onClick={(event) => this.handleImage(event)} />
                                </div>
                              </Col>
                            ))
                          }
                        </Row>
                      </Col>
                    }
                    <Col span={24}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                          <Title level={2} style={{ marginTop: 0, marginBottom: 5 }}>{productDetail?.name}</Title>
                          <Title level={4} style={{ marginTop: 0, marginBottom: 5 }}>{productDetail?.photographer}</Title>
                          <div style={{ marginBottom: 20 }}>{productDetail?.description}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <span style={{ fontSize: 20, fontWeight: 'bold', }}>{productDetail?.price}  MMK</span>
                          <br />
                          <span style={{ fontSize: 18, color: 'gray' }}>Instock {productDetail?.quantity}</span>
                          <Divider />
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={buttonStyle}>
                              <NJVButton
                                className='center-element'
                                icon={<SvgMinusOutlined width={20} height={20} />}
                                type='text'
                                shape='circle'
                                onClick={this.handleDecrease}
                              />
                              <span>{quantity}</span>
                              <NJVButton
                                className='center-element'
                                icon={<SvgPlusOutlined width={20} height={20} />}
                                type='text'
                                shape='circle'
                                onClick={this.handleIncrease}
                              />
                            </div>
                            <Button disabled={productDetail?.quantity === 0} type="primary" size='large' shape='round' style={{ backgroundColor: productDetail?.quantity !== 0 ? Theme.colors.primary : '#f1f1f1', height: 40, paddingInline: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }} onClick={this.handleAddtoCart}>
                              <SvgCartAddFilled width={18} height={18} color="#fff" />Add to Cart
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            )
          }
        </>
      </Row>
    )
  }
}

export default compose(withRouter)(ProductDetail)