import {
    CloseOutlined
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Image, Modal, QRCode, Row, Skeleton, Tabs, Typography } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import TravelPng from '../../asset//travel.png'
import PlatinumAward from '../../asset/award_grey.png'
import ShipperAward from '../../asset/award_red.png'
import GoldAward from '../../asset/award_yellow.png'
import BeautyPng from '../../asset/beauty_fashion.png'
import FoodPng from '../../asset/foods.png'
import HotelPng from '../../asset/hotel.png'
import LifestylePng from '../../asset/lifestyle.png'
import EventPng from '../../asset/media_events.png'
import qrImage from '../../asset/ninja_qr_image.png'
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg'
import qrCode from '../../asset/qr.svg'
import WellnessPng from '../../asset/wellness.png'
import { MainTitle, NJVButton, NJVCard, NJVTabs } from '../../components/core-component'
import Theme from '../../components/theme'
import { GetListFromEnumObject } from '../../core/nvm-utils'
import { logClick, logView } from '../../firebase/analyticsUtils'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { setBannerSlide, setLoyaltyInfo, setPopularShops, setShopTypes } from '../../redux/loyalty-slice'
import CustomPath from '../../routes/custom-path'

const awardMap = {
    'Shipper': ShipperAward,
    'Gold': GoldAward,
    'Platinum': PlatinumAward,
}

const imageMap = {
    WELLNESS: WellnessPng,
    TRAVEL: TravelPng,
    HOTEL: HotelPng,
    MEDIA_AND_EVENT: EventPng,
    BEAUTY_AND_FASHION: BeautyPng,
    LIFESTYLE: LifestylePng,
    FOOD: FoodPng
};

const CACHE_DURATION_MINUTES = 180;

class LoyaltyPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loyaltyInfo: null,
            bannerItems: [],
            popularShops: [],
            shopTypes: [],
            openQR: false,
            active: '1'
        }
    }

    componentDidMount() {
        this.checkAndFetchData(this.props.loyaltyInfo, this.fetchLoyaltyInfo)
        this.checkAndFetchData(this.props.bannerSlide, this.fetchBannerSlide)
        this.checkAndFetchData(this.props.popularShops, this.fetchPopularShops)
        this.checkAndFetchData(this.props.shopTypes, this.fetchShopTypes)
        logView('loyalty_main_screen')
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loyaltyInfo !== this.props.loyaltyInfo) {
            this.checkAndFetchData(this.props.loyaltyInfo, this.fetchLoyaltyInfo);
        }
        if (prevProps.bannerSlide !== this.props.bannerSlide) {
            this.checkAndFetchData(this.props.bannerSlide, this.fetchBannerSlide);
        }
        if (prevProps.popularShops !== this.props.popularShops) {
            this.checkAndFetchData(this.props.popularShops, this.fetchPopularShops);
        }

        if (prevProps.shopTypes !== this.props.shopTypes) {
            this.checkAndFetchData(this.props.shopTypes, this.fetchShopTypes);
        }
    }

    checkAndFetchData = (dataObj, fetchFunction) => {
        const lastFetched = dataObj?.lastFetched;

        if (!lastFetched || moment().diff(moment(lastFetched), 'minutes') > CACHE_DURATION_MINUTES) {
            fetchFunction()
        } else {
            this.setState({
                [dataObj.name]: dataObj.data
            })
        }
    }

    fetchLoyaltyInfo = async () => {
        this.setState({
            isFetchingLoyaltyInfo: true
        })
        await ApiHandler({ url: Api.loyalty_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    loyaltyInfo: response
                })
                this.props.dispatch(setLoyaltyInfo(response))
            })
            .catch((error) => {
                // handle error
            }).finally(() => {
                this.setState({
                    isFetchingLoyaltyInfo: false
                })
            })
    }

    fetchBannerSlide = async () => {
        this.setState({
            isFetchingBannerSlide: true
        })
        const requestParams = {
            'bannerType': 'LOYALTY_SHOP_DASHBOARD'
        }
        await ApiHandler({ url: Api.banner_slide, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    isFetchingBannerSlide: false
                })
                this.setState({
                    bannerItems: response
                })
                this.props.dispatch(setBannerSlide(response))
            })
            .catch((error) => {
                this.setState({
                    isFetchingImageData: false
                })
            })
    }

    fetchPopularShops = async () => {
        this.setState({
            isFetchingPopularShops: true
        })
        await ApiHandler({ url: Api.popular_shops, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    isFetchingPopularShops: false
                })

                this.setState({
                    popularShops: response
                })
                this.props.dispatch(setPopularShops(response))
            })
            .catch((error) => {
                this.setState({
                    isFetchingPopularShops: false
                })
            })
    }

    fetchShopTypes = async () => {
        this.setState({
            isFetchingShopTypes: true
        })
        const requestParams = {
            enumType: 'LOYALTY_SHOP_TYPE'
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, requestParams })
            .then((response) => {
                this.setState({
                    shopTypes: GetListFromEnumObject(response)
                })
                this.props.dispatch(setShopTypes(GetListFromEnumObject(response)))
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isFetchingShopTypes: false
                })
            })
    }

    popularBrandsSection = () => {
        const { popularShops, isFetchingPopularShops } = this.state;
        const imageContainer = {
            border: '0.5px solid #ccc',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            boxShadow: '0 1px 6px -1px rgba(0, 0, 0, 0.15)'
        }

        return (
            <>
                {
                    isFetchingPopularShops ? <Skeleton active /> :
                        <Row gutter={[16, 16]}>
                            <>
                                {popularShops?.content?.slice(0, 7).map((shopData, index) => (
                                    <Col
                                        key={index}
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={8}
                                        style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                    >
                                        <Link to={`${CustomPath.loyalty_shop_detail}/${shopData.id}`} state={{ prevRoute: CustomPath.loyalty }}>
                                            <Image
                                                src={shopData.shopProfileUrl}
                                                preview={false}
                                                style={{ cursor: 'pointer', objectFit: 'cover', borderRadius: '50%', width: 80, height: 80, border: '0.5px solid #ccc', boxShadow: '0 1px 6px -1px rgba(0, 0, 0, 0.15)' }}
                                                fallback={ThumbnailImg}
                                            />
                                        </Link>
                                    </Col>
                                ))}

                                {popularShops?.content?.length > 7 && (
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={8}
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Link to={CustomPath.shops} state={{ shops: popularShops?.content }}>
                                            <div style={imageContainer}>
                                                <span style={{ color: Theme.colors.primary }}>See All</span>
                                            </div>
                                        </Link>
                                    </Col>
                                )}
                            </>
                        </Row>
                }
            </>
        )
    }

    categoriesSection = () => {
        const { shopTypes, isFetchingShopTypes } = this.state;
        return (
            <>
                {
                    isFetchingShopTypes ? <Skeleton /> :
                        <Row gutter={[24, 24]}>
                            {
                                shopTypes.map((type, index) => (
                                    <Col key={index} lg={3} md={4} sm={8} xs={8}>
                                        <Flex vertical justify='center' align='center' gap={5}>
                                            <Link to={CustomPath.shops} state={{ shopType: type }}>
                                                <Image width={80} src={imageMap[type.value]} preview={false} alt={type.label} style={{ cursor: 'pointer', objectFit: 'cover' }} />
                                            </Link>
                                            <span style={{ color: Theme.colors.primary, fontWeight: 'bold' }}>{type.label}</span>
                                        </Flex>
                                    </Col>
                                ))}
                        </Row>
                }
            </>
        )
    }

    render() {
        const { loyaltyInfo, isFetchingBannerSlide, bannerItems, isFetchingLoyaltyInfo, openQR } = this.state
        const { isMobileSize } = this.props;

        const items = [
            {
                key: '1',
                label: <span style={{ fontSize: 18 }}>Most Popular Brands</span>,
                children: this.popularBrandsSection(),
            },
            {
                key: '2',
                label: <span style={{ fontSize: 18 }}>Reward Categories</ span>,
                children: this.categoriesSection(),
            },
        ]

        return (
            <>
                <Modal
                    title={null}
                    footer={null}
                    centered
                    open={openQR}
                    closeIcon={false}
                    onOk={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    okText="Logout"
                    cancelText="Cancel"
                    width={350}
                >
                    <div style={{ textAlign: 'center' }}>
                        <QRCode
                            errorLevel="H"
                            size={300}
                            iconSize={300 / 4}
                            value={this.props.shipperId}
                            icon={qrImage}
                            style={{
                                width: 'auto',
                                border: 0
                            }}
                        />
                        <h3>{this.props.shipperId}</h3>
                        <NJVButton type='primary' shape='circle' style={{ backgroundColor: 'red', borderRadius: '40 !important' }} onClick={() => {
                            this.setState({ openQR: false })
                        }}>
                            <CloseOutlined />
                        </NJVButton>
                    </div>
                </Modal>
                <Row>
                    <Col span={24}>
                        <MainTitle label={i18next.t("ninja_rewards")} style={{ marginBottom: 20 }} />
                        <NJVCard cardbgcolor={Theme.colors.primary}>
                            <Row>
                                {
                                    isFetchingLoyaltyInfo ? <Skeleton /> :
                                        <>
                                            <Col lg={2} md={2} sm={0} xs={0}></Col>
                                            <Col lg={20} md={20} sm={24} xs={24}>
                                                <Row>
                                                    <Col lg={14} md={14} sm={16} xs={16}>
                                                        <Flex gap={5} justify='space-between'>
                                                            <Flex vertical justify='space-between' gap={isMobileSize ? 0 : 10}>
                                                                {!isMobileSize && <span style={{ color: '#fff', fontWeight: 500 }}>Member</span>}
                                                                <Image src={awardMap[loyaltyInfo?.currentMonthLevel]} preview={false} width={50} height={50} />
                                                                <Typography.Text style={{ color: '#fff', fontSize: 20, fontWeight: 600 }}>{loyaltyInfo?.currentMonthLevel}</Typography.Text>
                                                            </Flex>
                                                            <Flex vertical justify='space-between' style={{ color: '#fff' }} gap={isMobileSize ? 0 : 10}>
                                                                <span style={{ fontWeight: 500 }}>ID No - {loyaltyInfo?.uniqueId}</span>
                                                                <Flex vertical={isMobileSize ? false : true} align='center' justify='center' gap={5} style={{ fontWeight: 700 }}>
                                                                    <span style={{ fontSize: 30 }}>{loyaltyInfo?.currentMonthPoint}</span>
                                                                    <span>Points</span>
                                                                </Flex>
                                                                <Link to={CustomPath.point} state={{ info: loyaltyInfo }} style={{ textDecoration: 'underline', color: '#fff', fontWeight: 600, textAlign: 'center' }}>Point Information</Link>
                                                            </Flex>
                                                        </Flex>
                                                    </Col>
                                                    <Col lg={10} md={10} sm={8} xs={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                        <Image src={qrCode} preview={false} width={isMobileSize ? 80 : 100} height={isMobileSize ? 80 : 100} onClick={() => {
                                                            this.setState({ openQR: true })
                                                        }} style={{ cursor: 'pointer' }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                }
                            </Row>
                        </NJVCard>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                {isFetchingBannerSlide ? <Skeleton active /> :
                                    <>
                                        {
                                            bannerItems?.length > 0 &&
                                            <>
                                                <Typography.Title level={4} style={{ marginBottom: 20 }}>Current Deals</Typography.Title>
                                                {/* <Carousel
                                                    style={{ textAlign: 'center' }}
                                                    height={200}
                                                    draggable={true}
                                                    arrows
                                                    autoplay
                                                    infinite
                                                >
                                                    {
                                                        bannerItems?.map((item) => (
                                                            <div key={item.id}
                                                            >
                                                                <a
                                                                    href={item.link && item.link !== '' ? item.link : ''}
                                                                    rel="noreferrer"
                                                                    target={item.link && item.link !== '' ? '_blank' : '_self'}
                                                                    onClick={() => logClick('loyalty_main_screen', item.name)}
                                                                >
                                                                    <Image
                                                                        alt={item.name}
                                                                        style={{
                                                                            width: '100%',
                                                                            height: 200,
                                                                            borderRadius: 10,
                                                                            objectFit: 'cover',
                                                                            cursor: item.link && item.link !== '' ? 'pointer' : 'default'
                                                                        }}
                                                                        src={item.photoUrl}
                                                                        fallback={ThumbnailImg}
                                                                        preview={false}
                                                                    />
                                                                </a>
                                                            </div>
                                                        ))}
                                                </Carousel> */}
                                                <Swiper modules={[Autoplay]}
                                                    slidesPerView={1}
                                                    spaceBetween={20}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        dynamicBullets: true,
                                                    }}
                                                    breakpoints={{
                                                        640: {
                                                            slidesPerView: 1,
                                                        },
                                                        768: {
                                                            slidesPerView: 2,
                                                        },
                                                        1200: {
                                                            slidesPerView: 3,
                                                        },
                                                    }}
                                                >
                                                    {
                                                        bannerItems.map((item, index) => {
                                                            return (
                                                                <SwiperSlide key={item.id}>
                                                                    {
                                                                        item.link && item.link !== '' ?
                                                                            (
                                                                                <a
                                                                                    href={item.link}
                                                                                    rel="noreferrer"
                                                                                    target={'_blank'}
                                                                                    onClick={() => logClick('loyalty_main_screen', item.name)}
                                                                                >
                                                                                    <Image
                                                                                        alt={item.name}
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            height: 200,
                                                                                            borderRadius: 10,
                                                                                            objectFit: 'cover',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        src={item.photoUrl}
                                                                                        fallback={ThumbnailImg}
                                                                                        preview={false}
                                                                                    />
                                                                                </a>
                                                                            ) :
                                                                            <Image
                                                                                alt={item.name}
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: 200,
                                                                                    borderRadius: 10,
                                                                                    objectFit: 'cover',
                                                                                }}
                                                                                src={item.photoUrl}
                                                                                fallback={ThumbnailImg}
                                                                                preview={false}
                                                                            />
                                                                    }

                                                                </SwiperSlide>
                                                            )
                                                        })
                                                    }
                                                </Swiper>
                                            </>
                                        }
                                    </>
                                }
                            </Col>
                            <Col span={24}>
                                <NJVTabs
                                    onChange={(activeKey) => this.setState({ active: activeKey })}
                                    items={items}
                                    style={{
                                        background: '#fff', borderRadius: 12, padding: 15,
                                    }}
                                />
                            </Col>
                        </Row >
                    </Col >
                </Row >
            </>
        )
    }
}

export default compose(withRouter)(LoyaltyPage)