import { Col, Form, message, Row } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { MainTitle, NJVButton, NJVCard, NJVInput } from '../components/core-component'
import Theme from '../components/theme'
import Api from '../network/api'
import { HTTP_METHOD } from '../network/httpMethod'
import { MEDIA_TYPE } from '../network/mediaType'
import { ApiHandler } from '../network/network-manager'

export default class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isChanging: false
    }
    this.forRef = React.createRef()
  }

  handleChangePassword = (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      message.destroy()
      message.error("Password does not match")
      return
    }

    this.setState({
      isChanging: true
    })

    const requestData = {
      oldPassword,
      newPassword
    }

    ApiHandler({ url: Api.shipper_change_password, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true })
      .then(() => {
        message.success("Password changed successfully")
        this.forRef.current.resetFields();
      }).catch(() => { })
      .finally(() => {
        this.setState({
          isChanging: false
        })
      })
  }
  render() {

    return (
      <Row>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <MainTitle label={i18next.t("change_password")} />
            </Col>
            <Col span={24}>
              <NJVCard>
                <Form onFinish={this.handleChangePassword} layout='vertical' ref={this.forRef}>
                  <Row gutter={[16]}>
                    <Col lg={6} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="oldPassword"
                        label={i18next.t("current_password")}
                        rules={[
                          {
                            required: true,
                            message: i18next.t("msg_current_password")
                          }
                        ]}
                      >
                        <NJVInput size="large" ispasswordinput />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="newPassword"
                        label={i18next.t("new_password")}
                        rules={[
                          {
                            required: true,
                            message: i18next.t("msg_new_password")
                          }
                        ]}
                      >
                        <NJVInput size="large" ispasswordinput />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="confirmPassword"
                        label={i18next.t("confirm_password")}
                        rules={[
                          {
                            required: true,
                            message: i18next.t("msg_confirm_password")
                          }
                        ]}
                      >
                        <NJVInput size="large" ispasswordinput />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                      <Form.Item label="&nbsp;">
                        <NJVButton size="large" block buttonprimary={Theme.colors.primary} type="primary" htmlType="submit">Save</NJVButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </NJVCard>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
