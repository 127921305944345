import {
    HeartFilled,
    HeartOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Divider, Flex, Image, Modal, Rate, Row, Skeleton, Spin, Tabs, Tag, Typography } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg'
import NoBranch from '../../asset/no_branch.png'
import NoPromotion from '../../asset/no_promotion.png'
import { NJVButton, NJVCard, NJVTabs } from '../../components/core-component'
import { SvgFacebookFilled, SvgMessengerFilled, SvgPackLocationFilled, SvgPhoneOutlined, SvgSiteBrowserFilled, SvgTelegramFilled, SvgViberFilled } from '../../components/custom-svgIcon'
import { BackTitleCompo } from '../../components/general-component'
import Theme from '../../components/theme'
import { Constant } from '../../core/constant'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'

class LoyaltyShopDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            promotionData: [],
            branchData: [],
            feedbackData: [],
            shopId: this.props?.params?.id,
            openFeedbackModal: false,
            pageType: 'INFORMATION',
            page: 0,
            size: Constant.pageSize,
            isLike: false
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchFeedback()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.pageType !== this.state.pageType) {
            if (this.state.pageType === 'BRANCH') {
                this.fetchBranch()
            }

            if (this.state.pageType === 'PROMOTIONS') {
                this.fetchPromotion()
            }
        }
    }

    fetchData = async () => {
        this.setState({
            isFetchingData: true,
        })
        await ApiHandler({ url: Api.loyalty_shop, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.state.shopId })
            .then(response => {
                this.setState({
                    data: response,
                    pageType: 'INFORMATION',
                })
            }).catch(err => { })
            .finally(() => {
                this.setState({
                    isFetchingData: false
                })
            })
    }

    fetchFeedback = async () => {
        this.setState({
            isFetchingFeedback: true,
        })
        await ApiHandler({ url: Api.feedback_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { loyaltyStopId: this.state.shopId } })
            .then(response => {                
                this.setState({
                    feedbackData: response,
                    isLike: response.like
                })
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isFetchingFeedback: false
                })
            })
    }

    fetchBranch = async (pageNumber) => {
        const { data, page, size } = this.state

        if (data?.branchCode !== undefined && data?.branchCode !== '') {
            this.setState({
                isFetchingBranch: true,
            })
            const requestParams = {
                page: pageNumber ? pageNumber : page,
                size,
                branchCode: data?.branchCode,
                userStatus: data.userStatus,
            }
            await ApiHandler({ url: Api.loyalty_shop_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
                .then(response => {
                    this.setState({
                        branchData: response
                    })
                }).catch(err => { })
                .finally(() => {
                    this.setState({
                        isFetchingBranch: false
                    })
                })
        }
    }

    fetchPromotion = async () => {
        this.setState({
            isFetchingPromotion: true
        })
        const requestParams = {
            loyaltyShopId: this.state.shopId,
        }
        await ApiHandler({ url: Api.loyalty_promotion, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    promotionData: response
                })
            })
            .catch(err => { })
            .finally(() => {
                this.setState({
                    isFetchingPromotion: false
                })
            })
    }

    handleLikeButton = () => {
        this.setState({ isLike: !this.state.isLike }, () => this.handleFeedback());
    }

    handleFeedbackButton = () => {
        this.setState({ openFeedbackModal: false }, () => this.handleFeedback())
    }

    handleFeedback = async () => {
        const { shopId, feedbackData, isLike } = this.state;

        const requestData = {
            loyaltyShopId: shopId,
            rating: feedbackData?.rating || 0,
            like: isLike
        }
        await ApiHandler({ url: Api.feedback, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true })
            .then(() => {
                this.fetchFeedback()
            }).catch((err) => { })
            .finally(() => {
            })
    }

    handleSegment = (value) => {
        this.setState({
            pageType: value
        })
    }

    handleBranchOnClick = (id) => {
        this.setState({
            shopId: id
        }, () => this.fetchData())
    }

    render() {
        const { data, isFetchingData, pageType, branchData, isFetchingBranch, isFetchingPromotion, promotionData, isFetchingFeedback, feedbackData, isLike, openFeedbackModal } = this.state

        return (
            <>
                <Modal width={350} open={openFeedbackModal} title={null} closeIcon={false} footer={null} onCancel={() => this.setState({ openFeedbackModal: false })}>
                    <Row style={{ textAlign: 'center' }}>
                        <Col span={24}>
                            <Typography.Title level={5} style={{ marginBlock: 10, marginBottom: 20 }}>Feedback</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Rate defaultValue={feedbackData?.rating} onChange={(value) => this.setState(prev => ({ feedbackData: { ...prev.feedbackData, rating: value } }))} />
                        </Col>
                        <Col span={24}>
                            <Row style={{ justifyContent: 'center', marginTop: 35 }}>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.handleFeedbackButton()}>Submit</NJVButton>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
                <Row>
                    <Col lg={4} md={2} sm={1} xs={1} />
                    <Col lg={16} md={20} sm={22} xs={22}>
                        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                            <BackTitleCompo label={"Shop Detail"} onClickRoute={this.props?.location?.state?.prevRoute} stateName={this.props?.location?.state?.stateName} stateData={this.props?.location?.state?.stateData} />
                        </Row>
                        {
                            isFetchingData ? <Skeleton /> :
                                <Row>
                                    <Col span={24} style={{ position: 'relative' }}>
                                        <Image src={data?.coverPhotoUrl} fallback={<ThumbnailImg />} width={'100%'} height={200} style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6, objectFit: 'cover' }} preview={false} alt={data?.fullName} />
                                        <div style={{ height: '70vh' }}>
                                            <NJVCard cardborder="true"
                                                style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30, position: 'absolute', top: 170, height: '538px', width: '100%', paddingInline: 25 }}
                                            >
                                                <Row gap={20} style={{ position: 'relative' }}>
                                                    <Col lg={6} md={6} sm={24} xs={24}>
                                                        {
                                                            data?.profileUrl && <Image src={data?.profileUrl} fallback={ThumbnailImg} alt={data?.fullName} preview={false} height={50} style={{ borderRadius: '50%', position: 'absolute', top: -44, background: '#fff', width: 100, height: 100 }} />
                                                        }
                                                    </Col>
                                                    <Col lg={18} md={18} sm={24} xs={24}>
                                                        <Flex vertical flex={1}>
                                                            <Flex justify='space-between' gap={2}>
                                                                <Typography.Title level={4} style={{ margin: 0 }}>{data?.fullName}</Typography.Title>
                                                                <NJVButton shape="circle"
                                                                    onClick={() => this.handleLikeButton()}
                                                                    icon={isLike ? <HeartFilled style={{ color: Theme.colors.primary }} /> : <HeartOutlined />} />
                                                            </Flex>
                                                            <Typography.Text style={{ color: Theme.colors.primary, fontWeight: 'bold', marginBottom: 10 }}>{data?.tag}</Typography.Text>
                                                            {
                                                                isFetchingFeedback ? <Spin indicator={<LoadingOutlined spin />} /> :
                                                                    <>
                                                                        <Flex onClick={() => this.setState({ openFeedbackModal: true })}>
                                                                            <Rate allowHalf defaultValue={feedbackData?.averageRating} disabled />
                                                                        </Flex>
                                                                        <span>{`${feedbackData?.likeCount}  ${feedbackData?.likeCount?.length > 0 ? 'Likes' : 'Like'}`}</span>
                                                                    </>
                                                            }
                                                        </Flex>
                                                    </Col>
                                                </Row>
                                                <NJVTabs tabPosition={"top"} style={{ marginBlock: 15 }} size='large' items={[
                                                    {
                                                        label: <span>Information</span>,
                                                        key: 'INFORMATION'
                                                    },
                                                    {
                                                        label: <span>Branch</span>,
                                                        key: 'BRANCH'
                                                    },
                                                    {
                                                        label: <span>Promotions</span>,
                                                        key: 'PROMOTIONS'
                                                    }
                                                ]}
                                                    onChange={this.handleSegment}
                                                    block />
                                                {
                                                    pageType === 'INFORMATION' ?
                                                        (
                                                            <Flex vertical gap={20}
                                                                style={{ maxHeight: 238, overflowY: 'auto' }}
                                                                className='custom-side-bar'
                                                            >
                                                                <Flex vertical gap={10}>
                                                                    <Typography.Title level={5} style={{ margin: 0 }}>Exclusive Member Discounts</Typography.Title>
                                                                    <Typography.Text>{data?.shopAbout?.split('\r\n').map((text, index) => (<span key={index}>{text} <br /></span>
                                                                    ))}</Typography.Text>
                                                                </Flex>
                                                                <Flex vertical gap={10}>
                                                                    <Typography.Title level={5} style={{ margin: 0 }}>Contact Information</Typography.Title>
                                                                    <Flex gap={5}>
                                                                        <span style={{ width: 20 }}>
                                                                            <SvgPackLocationFilled width={20} height={20} color={Theme.colors.primary} />
                                                                        </span>
                                                                        <Typography.Text>{data?.address1},{data.township?.name},{data.township?.division?.name}</Typography.Text>
                                                                    </Flex>
                                                                    <Flex gap={5}>
                                                                        <SvgPhoneOutlined width={20} height={20} color={Theme.colors.primary} />
                                                                        <Typography.Text>{data?.contactPhoneNumber}</Typography.Text>
                                                                    </Flex>
                                                                </Flex>
                                                                <Flex vertical gap={10} style={{ marginBottom: 10 }}>
                                                                    <Typography.Title level={5} style={{ margin: 0 }}>Social Media</Typography.Title>
                                                                    <Flex gap={10}>
                                                                        <a href={`https://www.facebook.com/${data?.contactFacebook}`} target='_blank' rel="noreferrer"><SvgFacebookFilled width={22} height={22} color="#1877F2" style={{ cursor: 'pointer' }} /></a>
                                                                        {
                                                                            data?.contactMessenger && data?.contactMessenger !== 'null' && <a href={`https://www.facebook.com/messages/t/${data?.contactMessenger}`} target='_blank' rel="noreferrer"><SvgMessengerFilled width={22} height={22} color="#0078FF" style={{ cursor: 'pointer' }} /></a>
                                                                        }
                                                                        {data?.contactViber && data?.contactViber !== 'null' && <a href={data?.contactViber} target='_blank' rel="noreferrer"><SvgViberFilled width={25} height={25} color="#7360F2" style={{ cursor: 'pointer' }} /></a>}
                                                                        {data?.contactTelegram && data?.contactTelegram !== 'null' && <a href={data?.contactTelegram} target='_blank' rel="noreferrer"><SvgTelegramFilled width={22} height={22} color="#24A1DE" style={{ cursor: 'pointer' }} /></a>}
                                                                        {data?.contactWebsite && data?.contactWebsite !== 'null' && <a href={data?.contactWebsite} target='_blank' rel="noreferrer"><SvgSiteBrowserFilled width={22} height={22} color="#7360F2" style={{ cursor: 'pointer' }} /></a>}
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        )
                                                        :
                                                        pageType === 'BRANCH' ?
                                                            <>
                                                                {
                                                                    isFetchingBranch ? <Skeleton /> :
                                                                        <Flex gap={10} vertical
                                                                            style={{ maxHeight: 238, overflowY: 'auto' }}
                                                                        >
                                                                            {
                                                                                branchData?.content?.length > 0 ? (
                                                                                    branchData?.content?.map((branch, index) => (
                                                                                        <Link key={index} onClick={() => this.handleBranchOnClick(branch.id)}>
                                                                                            <Flex gap={20} align='center'>
                                                                                                <Image src={branch?.shopProfileUrl} style={{ objectFit: 'cover', borderRadius: 10 }} alt={branch.fullName} width={80} height={80} preview={false} />
                                                                                                <div>
                                                                                                    <Typography.Title level={5} style={{ marginTop: 0 }}>{branch.fullName}</Typography.Title>
                                                                                                    <Tag color='#2db7f5'>{branch.shopType}</Tag>
                                                                                                </div>
                                                                                            </Flex>
                                                                                            {
                                                                                                index !== branchData?.content?.length - 1 && <Divider
                                                                                                    variant="dotted"
                                                                                                    style={{
                                                                                                        borderColor: '#7cb305',
                                                                                                        marginBlock: 10
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        </Link>
                                                                                    ))
                                                                                ) :
                                                                                    <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                                                                                        <Image src={NoBranch} alt='no branch' width={120} height={120} preview={false} />
                                                                                    </Flex>
                                                                            }
                                                                        </Flex >
                                                                }
                                                            </>
                                                            :
                                                            pageType === 'PROMOTIONS' ? (
                                                                <>
                                                                    {
                                                                        isFetchingPromotion ? <Skeleton /> :
                                                                            <div
                                                                                style={{ maxHeight: 238, overflowY: 'auto' }}>
                                                                                <Row>
                                                                                    <Col lg={4} md={4} sm={0} xs={0}></Col>
                                                                                    <Col lg={16} md={16} sm={24} xs={24}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                                                            {
                                                                                                promotionData.length > 0 ?
                                                                                                    promotionData.map((promotion, index) => (
                                                                                                        <NJVCard key={index} cardBgColor="#fff" style={{ overflow: 'hidden', marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}
                                                                                                        >
                                                                                                            {
                                                                                                                promotion.promotionPhotoUrl &&
                                                                                                                <div style={{ marginBottom: 20 }}>
                                                                                                                    <Image alt="example" src={promotion.promotionPhotoUrl} style={{ objectFit: 'cover', borderRadius: 10, width: 240 }} fallback={ThumbnailImg} />
                                                                                                                </div>
                                                                                                            }
                                                                                                            <span style={{ textAlign: 'center', marginTop: 30 }}>{promotion.description && promotion.description}</span>

                                                                                                        </NJVCard>
                                                                                                    )) :
                                                                                                    <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                                                                                                        <Image src={NoPromotion} alt='no promotion' width={120} height={120} preview={false} />
                                                                                                    </Flex>
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                    }
                                                                </>
                                                            ) : <></>
                                                }
                                            </NJVCard>
                                        </div>
                                    </Col>
                                </Row>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(LoyaltyShopDetail);