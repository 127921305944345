import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, Divider, Flex, Image, message, Row } from "antd";
import React from "react";
import { NJVButton } from "../../components/core-component";
import Theme from "../../components/theme";
import withRouter from '../../network/with-router';
import { ApiHandlerNoAuth } from "../../network/network-manager";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
const logo = require('../../asset/Logo.png');
const kpay_app_icon = require('../../asset/kpay_app_icon.png');

const number = 1234564;
const formattedNumber = number.toLocaleString();
class KBZRefererPage extends React.Component {

    constructor(props) {
        super()
        this.state = {
            kbz_order_info_url: null,
            kbz_prepay_id: null,
            kbz_payment_info: null,
            kbz_result_exists: false,
            opening_KBZ_Web: false
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const prepayId = queryParams.get("prepayId");
        this.fetchKpayRedirectUrl(prepayId)
    }

    goToKpayApplication = () => {
        this.setState({ opening_KBZ_Web: true })
        try {
            if (this.state.kbz_payment_info?.order_info_url) {
                window.open(this.state.kbz_payment_info?.order_info_url, '_blank')
            }
        } catch (e) { } finally { this.setState({ opening_KBZ_Web: false }) }
    }

    fetchKpayRedirectUrl = (paymentId) => {
        ApiHandlerNoAuth({ url: Api.payment_kbz_order_info, method: HTTP_METHOD.GET, specificId: paymentId })
            .then(response => this.setState({ kbz_payment_info: response, kbz_result_exists: true }))
            .catch(error => console.log(error))
            .finally(error => console.log(error));
    }

    render() {
        const { kbz_payment_info, kbz_result_exists, opening_KBZ_Web } = this.state
        return (
            <>
                <Flex justify="center" align="center" style={{ height: '100vh' }}>
                    <Row>
                        <Col span={2} />
                        <Col span={20}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>

                                    <Card className="card-shadow" style={{ padding: 0 }}>
                                        <Row gutter={[16, 0]}>
                                            <Col span={24}>
                                                <Flex justify="space-between">
                                                    <span>Total Amount</span>
                                                    <span>{kbz_payment_info?.amount?.toLocaleString()} MMK</span>
                                                </Flex>
                                                <Divider style={{ margin: '15px 0px' }} />
                                            </Col>
                                            <Col span={24}>
                                                <Flex justify="space-between">
                                                    <span>Payment Type</span>
                                                    <span>{kbz_payment_info?.transaction_type}</span>
                                                </Flex>
                                                <Divider style={{ margin: '15px 0px' }} />
                                            </Col>
                                            <Col span={24}>
                                                <Flex justify="space-between">
                                                    <span>Payment Method</span>
                                                    <span>KBZPay</span>
                                                </Flex>
                                            </Col>
                                            <Col span={24} style={{ marginTop: 20 }}>
                                                <NJVButton buttonprimary={Theme.colors.primary} type="primary" size="large" shape="rounded" style={{ width: '100%' }}
                                                    disabled={kbz_result_exists ? false : true}
                                                    loading={opening_KBZ_Web}
                                                    onClick={() => this.goToKpayApplication()}
                                                >
                                                    Confirm Payment
                                                </NJVButton>
                                            </Col>
                                        </Row>
                                    </Card>

                                </Col>

                            </Row>

                        </Col>
                    </Row>
                </Flex>
            </>

        )
    }
}
export default compose(withRouter)(KBZRefererPage)