import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Col, Divider, Form, Image, Row, Typography } from 'antd'
import React, { Component } from 'react'
import { NJVButton, NJVInput, NJVSelect } from "../../components/core-component"
import { SvgMinusOutlined, SvgPlusOutlined, SvgTrushFilled } from '../../components/custom-svgIcon'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { clearCart, decreaseItem, increaseItem, removeItem } from '../../redux/cart-slice'
import CustomPath from '../../routes/custom-path'

const { Text } = Typography
const buttonStyle = {
  backgroundColor: '#f5f5f5',
  width: 80,
  borderRadius: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  cursor: 'pointer',
  height: 32,
  marginTop: 40
}

const mobileButtonStyle = {
  backgroundColor: '#f5f5f5',
  width: 80,
  borderRadius: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  cursor: 'pointer',
  height: 20,
}

const empty_cart_image = require('../../asset/empty cart icon.png')

class CartItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      divisionList: [],
      townshipList: [],
      townshipMapList: [],
      isFetchingDivison: false,
      isFetchingData: false,
      notFound: false
    }
    this.formRef = React.createRef()
  }

  componentDidMount = () => {
    this.fetchDivisionAndTownship()

  }

  fetchDivisionAndTownship = async () => {
    this.setState({
      isFetchingDivison: true
    })
    try {
      const response = await ApiHandlerNoAuth({url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON});
      if (response) {
        this.setState({
          divisionList: response
        })
        const townshipMapList = {}
        response.forEach(division => {
          if (division.active && division.enableHomeDelivery) {
            let townshipList = []
            division.townshipDTOList && division.townshipDTOList.forEach(township => {
              if (township.active && township.enableHomeDelivery) {
                townshipList.push(township)
              }
            })
            townshipMapList[division.id] = townshipList
          }
        })
        this.setState({
          townshipMapList,
          isFetchingDivison: false
        })
      }
    } catch (error) {
    }
  }

  handleOnChange = (key, value) => {
    const { townshipMapList } = this.state;
    const townshipList = townshipMapList[value];
    if (key === 'division') {
      this.setState({
        townshipList
      })
      if (townshipList.length === 0) {
        this.formRef.current.setFieldValue('township', null)
      }
    }
  }

  handleIncrease = (item) => {
    this.props.dispatch(increaseItem(item))
  }

  handleDecrease = (item) => {
    this.props.dispatch(decreaseItem(item))
  }

  handleRemove = (id) => {
    this.props.dispatch(removeItem(id))
  }

  onFinish = async (values) => {
    const { cartItems } = this.props;
    const shipperId = this.props.params.uniqueId;
    const totalAmount = Object.values(cartItems)[0].reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const { name, phoneNumber, township, address } = values;

    const shippingItems = Object.values(cartItems)[0].map((item) => ({
      name: item.name,
      quantity: item.quantity,
      price: item.price
    }))

    this.setState({
      isFetchingData: true
    })

    try {
      const requestData = {
        "customerPhoneNo": phoneNumber,
        "customerName": name,
        "addressDetail": address,
        "townshipId": township,
        "orderType": "Home Delivery",
        "busGateId": null,
        "paymentOption": "Collect from customer",
        "deliveryCharges": 0.0,
        "codAmount": 0.0,
        "parcelAmount": totalAmount,
        "isSameDay": null,
        "shippingItems": shippingItems || null
      };
      const response = await ApiHandlerNoAuth({url: Api.public_create_store_voucher,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.JSON, requestData, specificId: shipperId});
      if (response && response.status === 200) {
        this.setState({
          isFetchingData: false
        })
        this.props.dispatch(clearCart())
        this.props.navigate(`${CustomPath.products}/${shipperId}`)
      }
    } catch (error) {
    }
  }

  render() {
    const { divisionList, townshipList, isFetchingDivison, isFetchingData } = this.state
    const { cartItems } = this.props;

    return (
      <Row gutter={[24, 24]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
          <Breadcrumb items={[
            {
              title: <a href={`${CustomPath.products}/${this.props.params.uniqueId}`}>Home</a>
            },
            {
              title: 'Shopping Cart'
            }
          ]} />
        </Col>
        {
          Object.keys(cartItems).length === 0 || this.props.params.uniqueId !== Object.keys(cartItems)[0] ?
            <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
              <Image
                width={200}
                preview={false}
                src={empty_cart_image} />
              <span style={{ marginTop: 20, fontSize: 18 }}>Your Cart is Empty! Browse our <a href={`${CustomPath.products}/${this.props.params.uniqueId}`}>Products</a> to find something you love.</span>
            </Col>
            :
            <>
              <Col lg={16} md={24} sm={24} xs={24}>
                <Row gutter={[16, 24]}>
                  {
                    Object.values(cartItems)[0]?.map((item, index) => {
                      const subTotal = item.price * item.quantity;
                      return (
                        <React.Fragment key={item.id}>
                          <Col lg={6} md={6} sm={0} xs={0}>
                            <div style={{ height: 100 }}>
                              <img src={item.imageUrl} alt={item.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                          </Col>
                          <Col lg={0} md={0} sm={6} xs={6}>
                            <div style={{ height: 90 }}>
                              <img src={item.imageUrl} alt={item.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                          </Col>
                          <Col span={18}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Row>
                                <Col lg={12} md={12} sm={0} xs={0}>
                                  <Text style={{ fontSize: 17, fontWeight: 600 }}>{item.name}</Text>
                                </Col>
                                <Col lg={12} md={12} sm={0} xs={0} style={{ textAlign: 'end' }}>
                                  <Text style={{ fontSize: 15, fontWeight: 500 }}>{subTotal} MMK</Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={0} md={0} sm={24} xs={24}>
                                  <Text style={{ fontSize: 17, fontWeight: 600 }}>{item.name}</Text>
                                </Col>
                                <Col lg={0} md={0} sm={24} xs={24}>
                                  <Text style={{ fontSize: 15, fontWeight: 500 }}>{subTotal}MMK</Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12} md={12} sm={0} xs={0}>
                                  <div style={buttonStyle}>
                                    <NJVButton
                                      className='center-element'
                                      icon={<SvgMinusOutlined width={20} height={20} />}
                                      type='text'
                                      size="small"
                                      shape='circle'
                                      onClick={() => this.handleDecrease(item)}
                                    />
                                    <span>{item.quantity}</span>
                                    <NJVButton
                                      className='center-element'
                                      icon={<SvgPlusOutlined width={20} height={20} />}
                                      type='text'
                                      shape='circle'
                                      size="small"
                                      onClick={() => this.handleIncrease(item)}
                                    />
                                  </div>
                                </Col>
                                <Col lg={12} md={12} sm={0} xs={0}>
                                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: 40 }}>
                                    <SvgTrushFilled width={20} height={20} color={Theme.colors.primary} style={{ cursor: 'pointer' }} onClick={() => this.handleRemove(item.id)} />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={0} md={0} sm={24} xs={24}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                                    <div style={mobileButtonStyle}>
                                      <NJVButton
                                        className='center-element'
                                        icon={<SvgMinusOutlined width={18} height={18} />}
                                        type='text'
                                        size="small"
                                        shape='circle'
                                        onClick={() => this.handleDecrease(item)}
                                      />
                                      <span>{item.quantity}</span>
                                      <NJVButton
                                        className='center-element'
                                        icon={<SvgPlusOutlined width={18} height={18} />}
                                        type='text'
                                        shape='circle'
                                        size="small"
                                        onClick={() => this.handleIncrease(item)}
                                      />
                                    </div>
                                    <SvgTrushFilled width={18} height={18} color={Theme.colors.primary} style={{ cursor: 'pointer' }} onClick={() => this.handleRemove(item.id)} />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          {
                            index !== Object.values(cartItems)[0].length - 1 && <Divider style={{ marginBlock: 5 }} />
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </Row>
              </Col>
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form onFinish={this.onFinish} ref={this.formRef} autoComplete='off'>
                  <Form.Item name="name" rules={[
                    {
                      required: true,
                      message: 'Enter Name'
                    },
                    {
                      max: 50,
                      message: 'Name cannot exceed 50 characters'
                    }
                  ]}>
                    <NJVInput type="text" size="large" placeholder="Name" bgcolor="#f1f1f1" />
                  </Form.Item>
                  <Form.Item name="phoneNumber" rules={[
                    {
                      required: true,
                      message: 'Enter Phone Number'
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Invalid Phone Number"
                    },
                    {
                      min: 6,
                      message: "Phone Number is too short"
                    },
                    {
                      max: 11,
                      message: "Phone Number is too long"
                    }
                  ]}>
                    <NJVInput type="text" size="large" placeholder="Phone Number" bgcolor="#f1f1f1" />
                  </Form.Item>
                  <Form.Item name="division" rules={[
                    {
                      required: true,
                      message: 'Select Division'
                    }
                  ]}>
                    <NJVSelect type="text" size="large" loading={isFetchingDivison} placeholder="Select Division" bgcolor="#f1f1f1" onChange={(event) => this.handleOnChange('division', event)} options={
                      divisionList && divisionList.map(division => {
                        return {
                          value: division.id,
                          label: division.name,
                          type: 'division'
                        }
                      })
                    } />
                  </Form.Item>
                  <Form.Item name="township" rules={[
                    {
                      required: true,
                      message: 'Select Township'
                    }
                  ]}>
                    <NJVSelect type="text" size="large" placeholder="Select Township" bgcolor="#f1f1f1" onChange={(event) => this.handleOnChange('township', event)} options={
                      townshipList && townshipList.map(township => {
                        return {
                          value: township.id,
                          label: township.name,
                          type: 'township'
                        }
                      })
                    } />
                  </Form.Item>
                  <Form.Item name="address" rules={[
                    {
                      required: true,
                      message: 'Enter Address'
                    }
                  ]}>
                    <NJVInput istextareainput="true" type="text" size="large" placeholder="Address" bgcolor="#f1f1f1" />
                  </Form.Item>
                  <Form.Item>
                    <NJVButton loading={isFetchingData} type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: Theme.colors.primary }}>Confirm</NJVButton>
                  </Form.Item>
                </Form>
              </Col>
            </>
        }
      </Row>
    )
  }
}

export default compose(withRouter)(CartItems)