
import { compose } from "@reduxjs/toolkit";
import { Col, Flex, Row, Statistic, message, Typography, Image } from "antd";
import i18next from "i18next";
import React from "react";
import CountUp from 'react-countup';
import CodBg from '../asset/cod-bg-image.png';
import { NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVSearchBoxCard } from "../components/core-component";
import { SvgCod, SvgCompletedParcel, SvgOngoingParcel, SvgReturnedParcel, SvgSearchOutlined, SvgTotalParcel } from '../components/custom-svgIcon';
import Theme from "../components/theme";
import '../css/dashboard.css';
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";
import { updateSelectedMenu } from "../redux/cookie-slice";
import { updateProfile } from "../redux/profile-slice";
import CustomPath from "../routes/custom-path";

const formatter = (value) => <CountUp end={value} separator="," />;

const titlefontStyle = {
    fontWeight: '600',
    fontSize: 16,
    color: '#000'
}

const bubbleStyle = {
    width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 100, border: '1px solid #E6EFF5'
}

class DashboardPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filter_name: '',
            totalCod: 0,
            totalParcel: 0,
            totalOngoingParcel: 0,
            totalCompleteParcel: 0,
            totalReturnParcel: 0,
            filterData: {},
            isSearchingData: false
        }
    }

    componentDidMount() {
        this.fetchProfile()
        this.props.dispatch(updateSelectedMenu("0"))
        this.fetchReport();
    }
    fetchProfile = async () => {
        try {
            const response = await ApiHandler({ url: Api.shipper_profile, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.props.dispatch(updateProfile(response))
            }
        } catch (error) {

        }
    }
    handleFilter = (event) => {
        this.setState({ filter_name: event.target.value })
    }

    track = async () => {
        const { filter_name } = this.state
        if (!filter_name || filter_name.length < 15) {
            message.error("Please enter tracking number first.")
            return
        }
        this.props.navigate(`${CustomPath.single_order_result}/${filter_name}`)
    }

    fetchReport = async () => {
        const { filterData } = this.state
        try {
            let requestParams = {}
            const { fromDate, toDate } = filterData;
            if (filterData) {
                if ((fromDate && !toDate) || (!fromDate && toDate) || (fromDate > toDate)) {
                    message.error("Invalid date")
                    return
                }
                requestParams = {
                    ...filterData,
                    fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : undefined,
                    toDate: toDate ? toDate.format("YYYY-MM-DD") : undefined
                }
            }
            const response = await ApiHandler({ url: Api.shipper_report, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams });
            if (response) {
                const { totalCod, totalParcel, totalOngoingParcel, totalCompleteParcel, totalReturnParcel } = response
                this.setState({
                    totalCod,
                    totalParcel,
                    totalOngoingParcel,
                    totalCompleteParcel,
                    totalReturnParcel,
                })
            }
        } catch (error) {
        } finally {
            this.setState({
                isSearchingData: false
            })
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state;
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData
        })
    }



    render() {
        const { totalParcel, totalOngoingParcel, totalCompleteParcel, totalReturnParcel, isSearchingData, totalCod } = this.state

        const statisticList = [
            {
                title: i18next.t("total_parcel"),
                value: totalParcel,
                color: '#10C200',
                icon: <SvgTotalParcel width={20} height={20} />
            },
            {
                title: i18next.t("inProgress_parcel"),
                value: totalOngoingParcel,
                color: '#C2BC00',
                icon: <SvgOngoingParcel width={20} height={20} />
            },
            {
                title: i18next.t("complete_parcel"),
                value: totalCompleteParcel,
                color: '#10C200',
                icon: <SvgCompletedParcel width={20} height={20} />
            },
            {
                title: i18next.t("return_parcel"),
                value: totalReturnParcel,
                color: '#C2002F',
                icon: <SvgReturnedParcel width={20} height={20} />
            }
        ]

        return (
            <Row gutter={[16, 16]}>
                <Col span={24} style={{}}>
                    <NJVSearchBoxCard placeholder={i18next.t('enter_tracking_id')} buttonname={i18next.t("track")} handleFilter={this.handleFilter} search={this.track} />
                    {/* <NJVCard style={{ marginBottom: 10 }} cardborder="true">
                        <Row>
                            <Col lg={20} md={20} sm={18} xs={18}>
                                <Flex align='center' justify='center'>
                                    <SvgSearchOutlined width={25} height={25} color="#718EBF" />
                                    <NJVInput
                                        inputborder="#fff"
                                        size='large'
                                        placeholder={i18next.t('enter_tracking_id')} onChange={this.handleFilter} />
                                </Flex>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={6}>
                                <NJVButton size="large" type="primary"
                                    block
                                    buttonprimary={Theme.colors.primary}
                                    onClick={() => this.track()}>{i18next.t("track")}</NJVButton>
                            </Col>
                        </Row>
                    </NJVCard> */}
                </Col>
                <Col lg={6} md={8} sm={12} xs={12}>
                    <NJVDatePicker datepickerborder="#E6EFF5" size="large" onChange={(value) => this.updateFilterData("fromDate", value)}
                        style={{ width: '100%' }} placeholder={i18next.t("from_date")} />
                </Col>
                <Col lg={6} md={8} sm={12} xs={12}>
                    <NJVDatePicker datepickerborder="#E6EFF5" size="large" onChange={(value) => this.updateFilterData("toDate", value)}
                        style={{ width: '100%' }} placeholder={i18next.t("to_date")} />
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                    <NJVButton buttonprimary={Theme.colors.primary} loading={isSearchingData} size="large" type="primary" style={{ width: '100%' }} onClick={() => this.fetchReport()}>{i18next.t("search")}</NJVButton>
                </Col>
                <Col span={24}>
                    <NJVCard cardpadding="0">
                        <Row>
                            <Col lg={14} md={14} sm={14} xs={24} style={{ display: 'flex', paddingLeft: 15 }}>
                                <Flex align='center' gap={20}>
                                    <Typography.Title style={{ marginBlock: 10 }} level={4}>{i18next.t("cod_amount")}:</Typography.Title>
                                    <Typography.Title style={{ marginBlock: 10, color: '#10C200' }} level={3}>{totalCod}</Typography.Title>
                                    <span style={bubbleStyle}><SvgCod width={20} height={20} /></span>
                                </Flex>
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={0}>
                                <Row>
                                    <Col lg={12} md={12} sm={20}
                                    >
                                        <Image src={CodBg} preview={false} height={74} width="100%" />
                                    </Col>
                                    <Col lg={12} md={12} sm={4}
                                        style={{
                                            background: 'linear-gradient(179.14deg, #E4002B 37.83%, #AB0125 65.75%, #A50027 99.26%)',
                                            borderTopRightRadius: 8,
                                            borderBottomRightRadius: 8
                                        }}
                                    >
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </NJVCard>
                </Col>
                {
                    statisticList.map((item, index) => (
                        <Col lg={6} md={6} xs={12} sm={12} key={index}>
                            <NJVCard>
                                <Statistic
                                    title={<Flex justify='space-between' align='center'>
                                        <span style={titlefontStyle}>{item.title}</span>
                                        <span style={bubbleStyle}>{item.icon}</span>
                                    </Flex>
                                    }
                                    value={item.value}
                                    valueStyle={{
                                        color: item.color,
                                        fontWeight: 700
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>
                    ))
                }
            </Row>
        )
    }
}

export default compose(withRouter)(DashboardPage)