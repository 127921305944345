import { compose } from '@reduxjs/toolkit'
import { Col, ConfigProvider, Image, Layout, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import i18next from 'i18next'
import cookies from "js-cookie"
import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import NinjaLogo from '../../asset/NinjaLogo.png'
import { SvgMyanmarFlag, SvgUKFlag } from '../../components/custom-svgIcon'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

const logo = require('../../asset/Ninjavan-logo-red-white.png')

class PublicLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLanguage: cookies.get("i18next") || "en"
        }
    }

    changeLanguage = () => {
        const { currentLanguage } = this.state
        if (currentLanguage === 'en') {
            i18next.changeLanguage("mm")
            this.setState({ currentLanguage: 'mm' })
        } else if (currentLanguage === 'mm') {
            i18next.changeLanguage("en")
            this.setState({ currentLanguage: 'en' })
        }
    }

    render() {
        return (
            <>
                <div style={{
                    position: 'fixed',
                    // height: 70,
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: 2,
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)'
                }}>
                    <Row>
                        <Col lg={3} md={1} sm={0} xs={0}></Col>
                        <Col lg={18} md={22} sm={24} xs={24}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Image style={{ borderRadius: '50%', cursor: 'pointer', height: 50, width: 'auto' }}
                                    src={NinjaLogo}
                                    preview={false}
                                    onClick={() => this.props.navigate(CustomPath.public_job)}
                                />
                                <div
                                    style={{ cursor: 'pointer', paddingRight: 10 }}
                                    onClick={() => this.changeLanguage()}>
                                    {
                                        this.state.currentLanguage === 'en' ?
                                            <SvgUKFlag width={35} height={35} />
                                            :
                                            <SvgMyanmarFlag width={35} height={35} />
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ConfigProvider theme={{
                    token: {
                        fontFamily: "Radio Canada"
                    },
                }}
                >
                    <Layout>
                        <Content style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'white',
                            marginTop: 50,
                            // marginBottom: 50,
                            overflow: 'auto',
                            padding: '20px 0px 40px',
                        }}>
                            <Outlet />
                        </Content>
                    </Layout>
                </ConfigProvider>
                <Row style={{ position: 'fixed', bottom: 0, left: 0, right: 0, background: '#000', alignItems: 'center', justifyContent: 'center', paddingBlock: 5, marginTop: 20 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <span style={{ color: '#fff' }}>Powered by </span><Image width={80} src={logo} preview={false} />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PublicLayout)