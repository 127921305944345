import { InfoCircleOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Card, Col, Modal, Row, Skeleton } from "antd";
import i18next from "i18next";
import Lottie from 'lottie-react';
import React from "react";
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import FailAnimation from '../../animate-lottie/lottie-404.json';
import ShippingAnimation from '../../animate-lottie/lottie-shipping.json';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import { NJVButton, NJVCard, NJVSpacer } from "../../components/core-component";
import { SvgArrowLeftFilled } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import CustomPath from "../../routes/custom-path";

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}


const initState = 1
const confirmState = 2
const successState = 3
const failState = 4


class ROMRequest extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            trackingNumber: null,
            data: props.location.state?.data || {},

            orderState: initState,

            openConfirmModal: false,

            searching: false,
            creating: false,
            fail_message_code: '076',
        }
    }


    createOrder = async () => {
        const { data, fail_message_code } = this.state
        this.setState({
            orderState: confirmState,
            creating: true
        })
        try {
            let requestParams = {
                trackingNumber: data.trackingNumber
            }
            const response = await ApiHandler({ url: Api.create_return_order, method: HTTP_METHOD.POST, requestParams })
            if (response) {
                this.setState({
                    orderState: successState,
                    trackingNumber: null,
                    data: null
                })
            }
        } catch (error) {
            let code = fail_message_code
            if (error && error.response && error.response.data && error.response.data.code) {
                code = error.response.data.code
            }
            this.setState({
                orderState: failState,
                fail_message_code: code
            })
        }
        this.setState({ creating: false })
    }

    GoToHistory = () => {
        this.setState({ openConfirmModal: false, orderState: initState })
        this.props.navigate(CustomPath.order_history)
    }

    back = () => {
        const { data } = this.state
        const param = {
            id: data.trackingNumber,
            isFromOrderHistory: true
        }
        this.props.navigate(
            CustomPath.order_detail,
            { state: { data: param } }
        );
    }

    render() {
        const { data, creating, searching, openConfirmModal, orderState, fail_message_code } = this.state
        return (
            <>
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.createOrder()}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                orderState === initState ?
                                    <>

                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={ShippingAnimation}
                                            loop={true}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_confirm_order")}</div>
                                        <NJVButton type='text' style={{ marginTop: 10, marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>Cancel</NJVButton>
                                        <NJVButton type='primary' style={{ marginTop: 10 }} onClick={() => this.createOrder()}>Confirm</NJVButton>

                                    </> :
                                    <></>
                            }
                            {
                                orderState === confirmState ?
                                    <>

                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={animationData}
                                            loop={true}
                                            autoplay={true}
                                        />

                                        <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_please_wait")}</div>
                                    </> :
                                    <></>
                            }
                            {
                                orderState === successState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginBlock: 10, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_parcel_create_success")}</div>
                                        <NJVButton type='primary' onClick={() => this.GoToHistory()}>{i18next.t("msg_go_to_parcel_history")}</NJVButton>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === failState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={FailAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, marginBottom: 20, fontSize: 15, fontWeight: '500' }}>
                                            {
                                                fail_message_code === '076' ?
                                                    <>
                                                        {i18next.t("msg_fail_to_create_parcel")}
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            fail_message_code === '077' ?
                                                                <>
                                                                    {i18next.t("msg_fail_to_create_parcel_077")}
                                                                </>
                                                                :
                                                                <>
                                                                    {i18next.t("msg_fail_to_create_parcel")}
                                                                </>
                                                        }
                                                    </>
                                            }



                                        </div>
                                        <NJVButton type='primary' onClick={() => this.setState({ openConfirmModal: false, orderState: initState })}>Yes</NJVButton>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </Modal>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                <NJVButton type='text' style={{ display: 'flex', justifyItems: 'center' }} onClick={() => this.back()}><SvgArrowLeftFilled width={25} height={25} /></NJVButton>
                                <span style={{ fontSize: 20, fontWeight: '600' }}> {i18next.t("rom")}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 40 }}>
                            {
                                searching ?
                                    <Skeleton active />
                                    :
                                    <Col span={24}>
                                        {
                                            data ?
                                                <>
                                                    <Row>
                                                        <Col span={24}>
                                                            <NJVCard>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <InfoCircleOutlined />
                                                                    <span style={{ marginLeft: 10 }}>Please confirm your ROM information</span>
                                                                </div>
                                                            </NJVCard>
                                                        </Col>
                                                    </Row>
                                                    <NJVSpacer height={20} />
                                                    <Row>
                                                        <Col span={24}>
                                                            <NJVCard>
                                                                {
                                                                    data.fromAddress && data.fromTownship ?
                                                                        <>
                                                                            <NJVLabel label={i18next.t("pickup_address")} />
                                                                            <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.name} `} />
                                                                            <NJVSpacer height={30} />
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                <NJVLabel label={i18next.t("sender_name")} />
                                                                <NJVValue value={`${data.senderName}`} />
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label={i18next.t("sender_phone_number")} />
                                                                <NJVValue value={data.senderPhoneNumber} />
                                                            </NJVCard>
                                                        </Col>
                                                    </Row>
                                                    <NJVSpacer height={20} />
                                                    <Row>
                                                        <Col span={24}>
                                                            <NJVCard>
                                                                <NJVLabel label={i18next.t("receiver_name")} />
                                                                <NJVValue value={`${data.recipientName}`} />
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label={i18next.t("receiver_phone_number")} />
                                                                <NJVValue value={data.recipientPhoneNumber} />
                                                                <NJVSpacer height={30} />
                                                                {
                                                                    data.toAddress && data.toTownship ?
                                                                        <>
                                                                            <NJVLabel label={i18next.t("receiver_address")} />
                                                                            <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </NJVCard>
                                                        </Col>
                                                    </Row>
                                                    <NJVSpacer height={20} />
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <NJVCard>
                                                                <NJVSpacer height={30} />
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label={i18next.t("pickup_time")} />
                                                                <NJVValue value={`${data.pickupDate}`} />
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label={i18next.t("order_type")} />
                                                                <NJVValue value={`${data.orderType}`} />
                                                                {
                                                                    data.paymentOption ?
                                                                        <>
                                                                            <NJVSpacer height={30} />
                                                                            <NJVLabel label={i18next.t("payment_option")} />
                                                                            <NJVValue value={`${data.paymentOption}`} />
                                                                        </>
                                                                        :
                                                                        <></>

                                                                }
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label="Note" />
                                                                <NJVValue value={`${data.note ? data.note : '------'}`} />
                                                                <NJVSpacer height={30} />
                                                                <NJVLabel label={i18next.t("cod")} />
                                                                {
                                                                    data.codAmount ?
                                                                        <NJVValue value={`${data.codAmount} MMK`} />
                                                                        :
                                                                        <NJVValue value={`0 MMK`} />
                                                                }
                                                            </NJVCard>
                                                        </Col>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <NJVButton type="primary" size="large" style={{ backgroundColor: Theme.colors.primary }} loading={creating} onClick={() => this.setState({ openConfirmModal: true })}>Create Now</NJVButton>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <></>
                                        }
                                    </Col>
                            }

                        </Row>
                    </Col>
                </Row>
            </>

        )
    }
}
export default compose(withRouter)(ROMRequest)